// ProfilePage.tsx
import UserDefaultAvatar from '../assets/images/default-user-avatar.jpg';
//import { FaEye, FaHeart } from 'react-icons/fa';
import { useCallback, useEffect, useState } from "react";
import { IDevProfile, fetchDev } from "../services/devService";
import { useNavigate, useParams } from "react-router-dom";
import SkillList from '../components/skill-list/skill-list';
import QABox, { QAItemType } from '../components/qa-box-grid/qa-box-grid';
import { formatNumber } from '../utils/helpers';
import { SPACES_ENDPOINT } from '../utils/constants';
import DOMPurify from 'dompurify';
import AskQuestionModal from '../modals/ask-question-modal/ask-question-modal';
import useUserStore from '../stores/userStore';
import useAppStore from '../stores/appStore';
import SelectPaymentQuestionModal from '../modals/select-payment-question-modal/select-payment-qeuestion-modal';
import { createGroup, createGroupPayment } from '../services/groupService';
import SelectPaymentGroupModal from '../modals/select-payment-group-modal/select-payment-group-modal';
import SocialList from '../components/social-list/social-list';

const ProfilePage = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    // Stores
    const { showAlertModal } = useAppStore();
    const { user } = useUserStore();

    // States
    const [profileData, setProfileData] = useState<IDevProfile | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showAskQuestionModal, setShowAskQuestionModal] = useState(false);
    const [showSelectPaymentModal, setShowSelectPaymentModal] = useState(false);
    const [showSelectPaymentGroupModal, setShowSelectPaymentGroupModal] = useState(false);
    const [askTitle, setAskTitle] = useState<string | null>(null);
    const [askQuestion, setAskQuestion] = useState<string | null>(null);

    useEffect(() => {
        if (id) {
            const loadProfile = async () => {
                try {
                    const devProfile = await fetchDev(Number(id), user?.userId);
                    setProfileData(devProfile);
                } catch (error) {
                    console.error('Error fetching dev profile:', error);
                } finally {
                    setIsLoading(false);
                }
            };
            loadProfile();
        }
    }, [user, id]);

    const onHandleAskQuestion = useCallback(async (paymentReference: string | undefined) => {
        setShowSelectPaymentModal(false);

        if (askTitle && askQuestion && profileData) {
            try {
                setIsLoading(true);

                const createdGroup = await createGroup(profileData.user_id, askTitle, askQuestion, paymentReference);
                if (createdGroup) {
                    navigate("/messages");
                    showAlertModal("Question Asked!", "The question was successfully asked to the developer.");
                }
            } catch (error) {
                console.log('Error while creating new group', error);
            } finally {
                setIsLoading(false);
                setAskTitle(null);
                setAskQuestion(null);
            }
        }
    }, [profileData, askTitle, askQuestion]);

    const onHandleJoinGroup = useCallback(async (paymentReference: string | undefined) => {
        setShowSelectPaymentGroupModal(false);

        if (profileData) {
            try {
                setIsLoading(true);

                const joinedGroup = await createGroupPayment(profileData.groupId, 2, paymentReference);
                if (joinedGroup) {
                    navigate("/messages");
                    showAlertModal("Joined Group!", "You successfully joined the group of " + profileData.settings.username ?? 'Unbekannt');
                }
            } catch (error) {
                console.log('Error while joining developer group', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [profileData]);

    return (
        <div className="page-container">
            {isLoading && !profileData && (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-light" role="status" />
                </div>
            )}
            {!isLoading && profileData && (
                <>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="square-profile-image-container">
                                <img className="square-profile-image" src={profileData.settings.avatar ? `${SPACES_ENDPOINT}${profileData.settings.avatar}` : UserDefaultAvatar} alt="User Avatar" />
                            </div>
                            <div className="row mt-5">
                                <div className="col-6 col-lg-4">
                                    <p className="profile-statistic-title">Amount of Answers</p>
                                    <h4 className="profile-statistic-subtitle">{profileData.groups.length} answer(s)</h4>
                                </div>
                                <div className="col-6 col-lg-4">
                                    <p className="profile-statistic-title">Response Time</p>
                                    <h4 className="profile-statistic-subtitle">{formatNumber(Number(profileData.settings.response_time_primary) ?? 0)} Hour(s)</h4>
                                </div>
                                <div className="col-6 col-lg-4">
                                    <p className="profile-statistic-title">Earnings</p>
                                    <h4 className="profile-statistic-subtitle">{formatNumber(profileData.performance.earnings ?? 0)} USDC</h4>
                                </div>
                            </div>
                            {user?.userId !== profileData.user_id && profileData.settings.wallet_public_key && profileData.settings.price_crypto_primary && profileData.settings.price_crypto_primary > 0 && (
                                <button className="btn btn-primary me-3 w-100 btn-ask-for mb-2 mt-3" onClick={() => {
                                    if (user) {
                                        setShowAskQuestionModal(true);
                                    } else {
                                        navigate("/login");
                                    }
                                }}>
                                    {user ? (
                                        `Ask for ${formatNumber(Number(profileData.settings.price_crypto_primary))} ${profileData.settings.token_fiat}`
                                    ) : (
                                        `Sign In to Ask`
                                    )}
                                </button>
                            )}
                            {!profileData.joinedGroup && user?.userId !== profileData.user_id && profileData.settings.wallet_public_key && profileData.settings.group_price && profileData.settings.group_price > 0 && (
                                <button className="btn btn-primary me-3 w-100 btn-ask-for mb-2 mt-3" onClick={() => {
                                    if (user) {
                                        setShowSelectPaymentGroupModal(true);
                                    } else {
                                        navigate("/login");
                                    }
                                }}>
                                    {user ? (
                                        `Join Group for ${formatNumber(Number(profileData.settings.group_price))} ${profileData.settings.token_fiat}`
                                    ) : (
                                        `Sign In to Join Group`
                                    )}
                                </button>
                            )}
                            {/*<div className="row g-0 mt-4">
                                <div className="col-auto me-5 d-flex align-items-center">
                                    <div className="statistic-icon-box">
                                        <FaEye />
                                    </div>
                                    <p className="statistic-icon-box-label ms-2">{profileData.performance.views}</p>
                                </div>
                                <div className="col-auto d-flex align-items-center">
                                    <div className="statistic-icon-box">
                                        <FaHeart />
                                    </div>
                                    <p className="statistic-icon-box-label ms-2">{profileData.performance.favourites}</p>
                                </div>
                            </div>*/}
                        </div>
                        <div className="col-12 col-md-8">
                            <h1 className="profile-username">{profileData.settings.username}</h1>
                            {profileData.roles.length > 0 && (
                                <p className="profile-statistic-subtitle">{profileData.roles[0].name}</p>
                            )}
                            <div className="profile-description mt-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(profileData.settings.description) }} />
                        </div>
                    </div>
                    {profileData.socials.length > 0 && (
                        <SocialList
                            headlineStyle={{ fontSize: 24, fontWeight: 700 }}
                            headline="Socials"
                            socials={profileData.socials}
                        />
                    )}
                    {profileData.skills.length > 0 && (
                        <div className="mt-5">
                            <SkillList
                                headlineStyle={{ fontSize: 24, fontWeight: 700 }}
                                headline="Skills"
                                skills={profileData.skills}
                            />
                        </div>
                    )}
                    {profileData.groups.length > 0 && (
                        <div className="mt-5">
                            <QABox
                                headline="Answers"
                                items={profileData.groups.map(group => ({
                                    group_id: group.group_id,
                                    title: group.title,
                                    price: `${formatNumber(Number(group.price_crypto_primary) ?? 0)} ${group.token_fiat}`,
                                    type: QAItemType.TEXT,
                                    content: group.question,
                                    access: true,
                                }))}
                            />
                        </div>
                    )}
                </>
            )}
            {showAskQuestionModal && (
                <AskQuestionModal
                    onStartPayment={(title: string, question: string) => {
                        setAskTitle(title);
                        setAskQuestion(question);
                        setShowAskQuestionModal(false);
                        setShowSelectPaymentModal(true);
                    }}
                    onClose={() => {
                        setShowAskQuestionModal(false);
                    }}
                    dev_userId={Number(id)}
                />
            )}
            {showSelectPaymentModal && profileData && profileData.settings.price_crypto_primary && profileData.settings.wallet_public_key && (
                <SelectPaymentQuestionModal
                    amount={Number(profileData.settings.price_crypto_primary)}
                    dev_id={profileData.user_id}
                    onPaymentDone={onHandleAskQuestion}
                    onClose={() => {
                        setShowSelectPaymentModal(false);
                        setAskQuestion(null);
                        setAskTitle(null);
                    }}
                />
            )}
            {showSelectPaymentGroupModal && profileData && profileData.settings.group_price && profileData.settings.wallet_public_key && (
                <SelectPaymentGroupModal
                    amount={Number(profileData.settings.group_price)}
                    dev_id={profileData.user_id}
                    onPaymentDone={onHandleJoinGroup}
                    onClose={() => {
                        setShowSelectPaymentGroupModal(false);
                    }}
                />
            )}
        </div>
    );
};

export default ProfilePage;
