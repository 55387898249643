import './profile-settings-dropdown.css';

import React, { useState, useRef, useEffect } from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { Tooltip, Overlay } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';
import { IRole, fetchRoles } from '../../services/roleService';

interface ProfileSettingsDropdownProps {
    className?: string;
    label: string;
    description?: string;
    role?: IRole | undefined,
    onSelectedRole: (role: IRole | null) => void;
}

const ProfileSettingsDropdown: React.FC<ProfileSettingsDropdownProps> = ({
    className,
    label,
    description,
    role,
    onSelectedRole,
}) => {
    // References
    const iconRef = useRef(null);

    // States
    const [showTooltip, setShowTooltip] = useState(false);
    const [selectedRole, setSelectedRole] = useState<IRole | null>(null);
    const [roles, setRoles] = useState<IRole[]>([]);

    useEffect(() => {
        const fetchAvailableOptions = async () => {
            try {
                const availableRoles = await fetchRoles();
                setRoles(availableRoles);
            } catch (error) {
                console.log("Error while fetching roles:", error);
            }
        }
        fetchAvailableOptions();
    }, []);

    useEffect(() => {
        setSelectedRole(role ?? null);
    }, [role]);

    const selectRole = (selectedOption: SingleValue<{
        value: any;
        label: any;
    }>) => {
        const role: IRole | null = selectedOption ? roles.find(role => role.name === selectedOption.value) || null : null;
        setSelectedRole(role)
        onSelectedRole(role);
    }

    // Custom styles for React Select
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: '#131313',
            border: 'none',
            borderRadius: '12px',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '500',
            boxShadow: 'none',
            color: 'white',
            padding: '8px',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#6033FF' : 'transparent',
            color: 'white',
            cursor: 'pointer',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#B1B5C1',
            opacity: 1,
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: 'white',
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: '#131313',
        }),
    };

    return (
        <div className={`profile-settings-input ${className}`}>
            <label className="profile-settings-label">
                {label}
                {description && (
                    <>
                        <span
                            ref={iconRef}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            className="d-none d-sm-inline-block ms-2"
                        >
                            <IoMdInformationCircleOutline className="info-icon" />
                        </span>
                        <Overlay
                            target={iconRef.current}
                            show={showTooltip}
                            placement="top"
                        >
                            <Tooltip
                                id={`tooltip-${label}`}
                            >
                                {description}
                            </Tooltip>
                        </Overlay>
                    </>
                )}
            </label>
            {description && (
                <span className="d-sm-none">
                    {/* Mobile view description */}
                    <p className="profile-settings-input-description">{description}</p>
                </span>
            )}
            <div>
                <Select
                    classNamePrefix="react-select"
                    className="input-modal-select"
                    options={roles.map(role => ({ value: role.name, label: role.name }))}
                    value={selectedRole ? { value: selectedRole.name, label: selectedRole.name } : null}
                    onChange={selectRole}
                    placeholder="Select a Role"
                    styles={customStyles}
                />
            </div>
        </div>
    );
};

export default ProfileSettingsDropdown;
