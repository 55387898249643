import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPaymentLink, createPaymentTransaction, verifyPayment } from '../../services/paymentService';
import QRCodeStyling from '@solana/qr-code-styling';
import { PublicKey, Transaction } from '@solana/web3.js';
import { TransferRequestURL, createQR, parseURL } from '@solana/pay';
import SolanaPay from '../../components/solana-pay/solana-pay';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton, useWalletModal } from '@solana/wallet-adapter-react-ui';
import { buildPaymentQuery, buildTransactionURL } from '../../utils/helpers';

import './select-payment-question-modal.css';

interface ModalProps {
    amount: number;
    dev_id: number;
    onPaymentDone: (reference: string | undefined) => void;
    onClose: () => void;
}

const SelectPaymentQuestionModal: React.FC<ModalProps> = ({ amount, dev_id, onPaymentDone, onClose }) => {
    const { connection } = useConnection();
    const wallet = useWallet();
    const walletModal = useWalletModal();

    // References
    const qrCodeContainerRef = useRef<HTMLDivElement>(null);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isVerifyingPayment, setIsVerifyingPayment] = useState<boolean>(false);
    const [paymentLink, setPaymentLink] = useState<string | null>(null);
    const [paymentReference, setPaymentReference] = useState<PublicKey | null>(null);
    const [qrCode, setQRCode] = useState<QRCodeStyling | null>(null);

    useEffect(() => {
        if (qrCode && qrCodeContainerRef.current) {
            qrCode.append(qrCodeContainerRef.current);
        }
    }, [qrCode]);

    const requestPaymentLink = useCallback(async () => {
        try {
            setIsLoading(true);

            const paymentLink = await createPaymentLink(dev_id, "question");
            if (!paymentLink) return;

            setPaymentLink(paymentLink);
            const transferRequestUrl = parseURL(paymentLink) as TransferRequestURL;
            if (!transferRequestUrl.reference?.length) return;

            const query = buildPaymentQuery(transferRequestUrl);
            const url = buildTransactionURL(query);
            const paymentQRCode = createQR(url, 256);

            setQRCode(paymentQRCode);
            setPaymentReference(transferRequestUrl.reference[0]);
        } catch (error) {
            console.error('Error while requesting payment link:', error);
        } finally {
            setIsLoading(false);
        }
    }, [dev_id]);

    const onClickVerifyPayment = useCallback(async () => {
        if (paymentReference && !isVerifyingPayment) {
            try {
                const isPaymentVerified = await verifyPayment(paymentReference.toString());
                if (isPaymentVerified) {
                    onPaymentDone(paymentReference.toString());
                }
            } catch (error) {
                console.log('Error while requesting payment link:', error);
            }
        }
    }, [paymentReference, isVerifyingPayment]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (paymentReference) {
            intervalId = setInterval(() => {
                onClickVerifyPayment();
            }, 3000); // Every 10 seconds
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [onClickVerifyPayment, paymentReference]);

    const onClickBrowserWallet = useCallback(async () => {
        if (!wallet.connected) {
            walletModal.setVisible(true);
        } else {
            if (paymentLink && wallet.publicKey && wallet.signTransaction) {
                try {
                    setIsVerifyingPayment(true);
                    const transferRequestUrl = parseURL(paymentLink) as TransferRequestURL;
                    const paymentTransaction = await createPaymentTransaction(transferRequestUrl, wallet.publicKey);
                    if (paymentTransaction) {
                        const transaction = Transaction.from(Buffer.from(paymentTransaction.transaction, 'base64'));
                        const signedTransaction = await wallet.signTransaction(transaction);

                        // Send the signed transaction
                        await connection.sendRawTransaction(signedTransaction.serialize());

                        setIsLoading(true);
                    }
                } catch (error) {
                    console.log('Error while fetching transaction object:', error);
                } finally {
                    setIsVerifyingPayment(false);
                }
            }
        }
    }, [wallet, paymentLink]);

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title">{qrCode ? `Scan To Pay ${amount} USDC` : 'Select Payment Method'}</h3>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div className="spinner-border text-light" role="status" />
                    </div>
                ) : (
                    <>
                        <div className="text-center mt-4" ref={qrCodeContainerRef} />
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            {qrCode ? (
                                <>
                                    <h5 className="text-light text-center mt-3">OR</h5>
                                    <div className="d-flex mb-2 mt-3">
                                        <WalletMultiButton />
                                        {wallet.connected && (
                                            isVerifyingPayment ? (
                                                <button className="btn btn-light ms-3" disabled={isLoading}>
                                                    <div className="spinner-border spinner-border-sm" role="status" />
                                                </button>
                                            ) : (
                                                <button className="btn btn-light ms-3" onClick={onClickBrowserWallet}>
                                                    {wallet.connected ? "Pay" : "Connect Wallet"}
                                                </button>
                                            )
                                        )}
                                    </div>
                                    <p className="mt-3">Make sure you have enough USDC in your wallet.</p>
                                </>
                            ) : (
                                <SolanaPay className="mt-2" onClick={requestPaymentLink} />
                            )}
                        </div>
                        <div className="text-end mt-4">
                            <button className="btn btn-light cancel-btn" onClick={onClose}>Close</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SelectPaymentQuestionModal;
