import React, { CSSProperties, useRef, useState } from 'react';
import { BsPlus, BsX } from 'react-icons/bs';
import { ISocial } from '../../services/socialService';

import './social-list.css';
import { Overlay, Tooltip } from 'react-bootstrap';
import { IoMdInformationCircleOutline } from 'react-icons/io';

interface SocialListProps {
    headlineStyle?: CSSProperties | undefined;
    headline: string;
    description?: string | undefined;
    socials: ISocial[];
    addTitle?: string;
    onAddClick?: () => void;
    onClickRemove?: (social: ISocial) => void;
}

const SocialList: React.FC<SocialListProps> = ({ headlineStyle, headline, description, socials, addTitle, onAddClick, onClickRemove }) => {
    // References
    const iconRef = useRef(null);

    // States
    const [showTooltip, setShowTooltip] = useState(false);

    const onClickSocial = (url: string | null) => {
        if (url) {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    }

    return (
        <>
            <h4 style={headlineStyle} className="skill-list-headline">
                {headline}
                {description && (
                    <>
                        <span
                            ref={iconRef}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            className="d-none d-sm-inline-block ms-2"
                        >
                            <IoMdInformationCircleOutline className="info-icon" />
                        </span>
                        <Overlay
                            target={iconRef.current}
                            show={showTooltip}
                            placement="top"
                        >
                            <Tooltip
                                id={`tooltip-${headline}`}
                            >
                                {description}
                            </Tooltip>
                        </Overlay>
                    </>
                )}
            </h4>
            {description && (
                <span className="d-sm-none">
                    {/* Mobile view description */}
                    <p className="profile-settings-input-description">{description}</p>
                </span>
            )}
            <div className="social-list">
                {socials.map((social, index) => (
                    <div className="social-item-container" key={index}>
                        <div className="social-item" onClick={() => onClickSocial(social.value)}>
                            {social.name}
                        </div>
                        {onClickRemove && (
                            <BsX className="delete-social-icon" onClick={() => onClickRemove(social)} />
                        )}
                    </div>
                ))}
                {socials.length <= 0 && onAddClick && addTitle && (
                    <div className="social-item add-social" onClick={onAddClick}>
                        <BsPlus className="add-icon" />
                        {addTitle}
                    </div>
                )}
            </div>
        </>
    );
};

export default SocialList;
