import { create } from "zustand";
import { AlertModalSettings } from "../modals/alert-modal/alert-modal";

interface AppState {
  alertModal: AlertModalSettings | null;
  showAlertModal: (title: string, message: string) => void;
  hideAlertModal: () => void;
}

const useAppStore = create<AppState>((set) => ({
  alertModal: null,
  showAlertModal: (title: string, message: string) =>
    set({ alertModal: { title, message } }),
  hideAlertModal: () => set({ alertModal: null }),
}));

export default useAppStore;
