// MessagePage.tsx
import { useEffect, useMemo, useState } from "react";
import ChatList from "../components/chat-list/chat-list";
import ChatMessageList from "../components/chat-message-list/chat-message-list";
import { IChat } from "../services/userService";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const MessagesPage = () => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    // Access specific query parameters
    const groupId = queryParams.id;

    // States
    const [preSelectedChatId, setPreSelectedChatId] = useState<number | null>(null);
    const [selectedChat, setSelectedChat] = useState<IChat | null>(null);
    const isMobileChatSelected = useMemo(() => selectedChat !== null && window.innerWidth <= 768, [selectedChat]);

    useEffect(() => {
        setPreSelectedChatId(groupId ? Number(groupId) : null);
    }, [groupId])

    return (
        <div className="page-container">
            <div className="row">
                {/* Hide chat list on mobile when a chat is selected */}
                <div className={`col-12 col-lg-5 col-xl-4 col-xxl-3 chat-list-section ${isMobileChatSelected ? 'd-none d-lg-block' : ''}`}>
                    <ChatList
                        preSelectedChatId={preSelectedChatId ?? undefined}
                        selectedChatId={selectedChat?.group_id}
                        onChatSelected={setSelectedChat}
                    />
                </div>

                {/* Show chat messages on mobile when a chat is selected */}
                <div className={`col-12 col-lg-7 col-xl-8 col-xxl-9 chat-message-list-section p-0 ${isMobileChatSelected ? 'd-block' : 'd-none d-lg-block'}`}>
                    <ChatMessageList
                        selectedChat={selectedChat ?? undefined}
                        onResetSelectedChat={() => setSelectedChat(null)}
                    />
                </div>
            </div>
        </div>
    );
};

export default MessagesPage;
