import React from 'react';

import './settings-item.css';

interface SettingsItemProps {
    title: string;
    subtitle?: string | undefined;
    buttonText?: string | undefined;
    onClick?: () => void;
}

const SettingsItem: React.FC<SettingsItemProps> = ({ title, subtitle, buttonText, onClick }) => {
    return (
        <div className="settings-item py-3">
            <div>
                <h4 className="mb-1 settings-item-title">{title}</h4>
                {subtitle && (
                    <p className="mb-0 settings-item-subtitle">{subtitle}</p>
                )}
            </div>
            {buttonText && onClick && (
                <button className="btn btn-outline-light settings-button" onClick={onClick}>
                    {buttonText}
                </button>
            )}
        </div>
    );
};

export default SettingsItem;
