import React, { CSSProperties, useState, useRef } from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { Tooltip, Overlay } from 'react-bootstrap';
import './profile-settings-input.css';

interface ProfileSettingsInputProps {
    className?: string;
    inputStyle?: CSSProperties;
    label: string;
    description?: string;
    value: string;
    placeholder: string;
    suffix?: string;
    allowTwoDecimalPlaces?: boolean;
    isNumeric?: boolean;
    disabled?: boolean;
    onChange: (newValue: string) => void;
}

const ProfileSettingsInput: React.FC<ProfileSettingsInputProps> = ({
    className, inputStyle, label, description, value, placeholder, suffix, allowTwoDecimalPlaces = false, isNumeric = false, disabled = false, onChange
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const iconRef = useRef(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;
        if (isNumeric) {
            newValue = newValue.replace(/[^0-9.]/g, '');
            if (!allowTwoDecimalPlaces) {
                newValue = newValue.replace(/(\..*)\./g, '$1')
                    .replace(/(\.\d*)\d/g, '$1');
            } else {
                newValue = newValue.replace(/(\..*)\./g, '$1')
                    .replace(/(\.\d{2})\d/g, '$1');
            }
        }
        onChange(newValue);
    };

    return (
        <div className={`profile-settings-input ${className}`}>
            <label className="profile-settings-label">
                {label}
                <span
                    ref={iconRef}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    className="d-none d-sm-inline-block ms-2"
                >
                    <IoMdInformationCircleOutline className="info-icon" />
                </span>
                <Overlay
                    target={iconRef.current}
                    show={showTooltip}
                    placement="top"
                >
                    <Tooltip
                        id={`tooltip-${label}`}
                    >
                        {description}
                    </Tooltip>
                </Overlay>
            </label>
            {description && (
                <span className="d-sm-none">
                    {/* Mobile view description */}
                    <p className="profile-settings-input-description">{description}</p>
                </span>
            )}
            <div className="input-suffix-wrapper">
                <input
                    type="text"
                    className="form-control profile-settings-text-input"
                    style={inputStyle}
                    value={value}
                    placeholder={placeholder}
                    onChange={handleChange}
                    disabled={disabled}
                />
                {suffix && <span className="input-suffix">{suffix}</span>}
            </div>
        </div>
    );
};

export default ProfileSettingsInput;
