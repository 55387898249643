import api from "../utils/axiosInstance";

export interface ISocial {
  social_id: number;
  name: string;
  creation_date: string;
  token_one: string | null;
  token_two: string | null;
  token_valid_till: string | null;
  value: string | null;
  verified: boolean;
}

// Fetch socials using axios
export const fetchSocials = async (
  page?: number,
  pageSize?: number
): Promise<ISocial[]> => {
  try {
    // Set up query parameters
    const params: { [key: string]: any } = {};
    if (page !== undefined) {
      params.page = page;
    }
    if (pageSize !== undefined) {
      params.pageSize = pageSize;
    }

    const response = await api.get("/socials", { params });

    return response.data.socials;
  } catch (error) {
    console.error("Error fetching socials:", error);
    return [];
  }
};
