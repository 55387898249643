import React, { useEffect, useState } from 'react';
import { BsCodeSlash, BsChatLeftText, BsGear } from "react-icons/bs";
import './sidebar.css';
import { Link, useLocation } from 'react-router-dom';
import useUserStore from '../../stores/userStore';
import { IconType } from 'react-icons';
import { logout } from '../../services/authService';
import { MdExitToApp, MdLogin } from 'react-icons/md';
import useUserSettingsStore from '../../stores/userSettingsStore';

interface MenuItem {
    id: number,
    name: string,
    icon: IconType,
    path: string,
    isVisible: boolean;
}

const DEFAULT_MENU_ITEMS: MenuItem[] = [
    { id: 1, name: 'Discover', icon: BsCodeSlash, path: '/', isVisible: true },
    { id: 2, name: 'Messages', icon: BsChatLeftText, path: '/messages', isVisible: false },
    { id: 3, name: 'Profile', icon: BsGear, path: '/settings', isVisible: true },
    { id: 4, name: 'Sign In', icon: MdLogin, path: '/login', isVisible: true },
    { id: 5, name: 'Log Out', icon: MdExitToApp, path: '/logout', isVisible: false }
];

interface SidebarProps {
    isSidebarVisible: boolean;
    toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarVisible, toggleSidebar }) => {
    const location = useLocation();
    const { user, removeUser } = useUserStore();
    const { removeUserSettings } = useUserSettingsStore();

    const [activeItem, setActiveItem] = useState<string>('Discover');
    const [menuItems, setMenuItems] = useState<MenuItem[]>(DEFAULT_MENU_ITEMS);

    useEffect(() => {
        const findActiveItem = () => {
            const active = menuItems.find(item => location.pathname === item.path);
            return active ? active.name : '';
        };

        setActiveItem(findActiveItem());
    }, [location]);

    useEffect(() => {
        const updatedMenuItems = menuItems.map(menuItem => {
            const updatedMenuItem = { ...menuItem };

            if (menuItem.id === 2 || menuItem.id === 5) {
                updatedMenuItem.isVisible = user !== null;
            } else if (menuItem.id === 4) {
                updatedMenuItem.isVisible = user === null;
            }

            return updatedMenuItem;
        });

        setMenuItems(updatedMenuItems);
    }, [user]);

    const onClickLogout = async () => {
        await logout();
        removeUser();
        removeUserSettings();
    }

    const handleItemClick = (item: MenuItem) => {
        if (activeItem !== item.name) {
            setActiveItem(item.name);
            toggleSidebar();
        } else {
            window.location.reload();
        }
    };

    return (
        <div className={`col-md-2 sidebar ${isSidebarVisible ? 'visible' : ''}`} id="sidebar">
            <ul className="nav flex-column">
                {menuItems.filter(menuItem => menuItem.isVisible).map((item) => {
                    const IconComponent = item.icon;

                    // Check if the menu item is the logout item
                    if (item.id === 5) {
                        return (
                            <li
                                key={item.name}
                                className={`nav-item ${activeItem === item.name ? 'active' : ''}`}
                                onClick={onClickLogout}
                            >
                                <div className={`nav-link text-white ${activeItem === item.name ? 'disabled' : ''}`} style={{ cursor: 'pointer' }}>
                                    <span className="icon me-2">
                                        <IconComponent />
                                    </span>
                                    {item.name}
                                </div>
                            </li>
                        );
                    }

                    // Render regular link for other items
                    return (
                        <li
                            key={item.name}
                            className={`nav-item ${activeItem === item.name ? 'active' : ''}`}
                        >
                            <Link
                                to={item.path}
                                className={`nav-link text-white`}
                                onClick={() => handleItemClick(item)}
                            >
                                <span className="icon me-2">
                                    <IconComponent />
                                </span>
                                {item.name}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Sidebar;
