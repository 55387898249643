import api from "../utils/axiosInstance";
import { IGroup } from "./groupService";
import { IRole } from "./roleService";
import { ISkill } from "./skillService";
import { ISocial } from "./socialService";

export interface IDev {
  user_id: number;
  username?: string;
  avatar?: string;
}

export const fetchDevs = async (
  page?: number,
  pageSize?: number,
  skillId?: number
): Promise<IDev[]> => {
  try {
    // Construct query parameters
    const params: Record<string, any> = {};
    if (page !== undefined && pageSize !== undefined) {
      params.page = page;
      params.pageSize = pageSize;
    }
    if (skillId !== undefined) {
      params.skillId = skillId;
    }

    const response = await api.get("/devs", { params });

    return response.data.devs;
  } catch (error) {
    console.error("Error fetching devs:", error);
    return [];
  }
};

export interface IDevProfile {
  user_id: number;
  settings: IUserSettings;
  performance: IUserPerformance;
  skills: ISkill[];
  socials: ISocial[];
  groupId: number;
  joinedGroup: boolean;
  groups: IGroup[];
  roles: IRole[];
}

export interface IUserSettings {
  avatar: string | null;
  avatar_thumb: string | null;
  username: string;
  description: string;
  wallet_public_key: string | null;
  price_crypto_primary: number | null;
  token_public_key_crypto_primary: string | null;
  price_fiat_primary: number | null;
  token_fiat: string | null;
  response_time_primary: number | null;
  group_price: number | null;
}

export interface IUserPerformance {
  questions_answered: number | null;
  response_rate: number | null;
  earnings: number | null;
  views: number | null;
  favourites: number | null;
}

export const fetchDev = async (
  devUserId: number,
  userId?: number
): Promise<IDevProfile | null> => {
  try {
    const params: Record<string, any> = {};
    if (userId !== undefined) {
      params.userId = userId;
    }

    const response = await api.get(`/devs/${devUserId}`, {
      params,
      withCredentials: true, // Include cookies since authentication may be required
    });

    return response.data as IDevProfile;
  } catch (error) {
    console.error("Error fetching dev:", error);
    return null;
  }
};
