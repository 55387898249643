import { useEffect, useState } from "react";
import ItemsGrid from "../components/items-grid/items-grid";
import { SPACES_ENDPOINT } from "../utils/constants";
import useSearchStore from "../stores/searchStore";
import { fetchSearchResults } from "../services/searchService";
import QABox, { QAItemType } from "../components/qa-box-grid/qa-box-grid";
import { formatNumber } from "../utils/helpers";
import useUserStore from "../stores/userStore";

// Add the useDebounce hook
const useDebounce = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState<string>(value);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
};

const SearchPage = () => {
    // States
    const [loading, setLoading] = useState<boolean>(true);

    // Stores
    const { keyword, searchResult, setSearchResult } = useSearchStore();
    const { user } = useUserStore();

    const debouncedKeyword = useDebounce(keyword, 500);

    useEffect(() => {
        const loadSearchResults = async () => {
            try {
                setLoading(true);

                const searchResult = await fetchSearchResults(user ? user.userId : null, debouncedKeyword);
                setSearchResult(searchResult);
            } catch (error) {
                console.error('Error while performing search:', error);
            } finally {
                setLoading(false);
            }
        };

        loadSearchResults();
    }, [user, setSearchResult, debouncedKeyword]);

    return (
        <div className="page-container">
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-light" role="status" />
                </div>
            ) : (
                searchResult && (
                    <>
                        {searchResult.devs.length > 0 && (
                            <ItemsGrid to="" title={"Found devs"} items={searchResult.devs.map(dev => ({
                                to: `/dev/${dev.user_id}`,
                                imageUrl: dev.avatar ? `${SPACES_ENDPOINT}${dev.avatar}` : undefined,
                                title: dev.username ?? "Unknown",
                            }))} />
                        )}
                        {searchResult.groups.length > 0 && (
                            <QABox headline="Found Questions" items={searchResult.groups.map(group => ({
                                group_id: group.group_id,
                                title: group.title,
                                price: `${formatNumber(Number(group.price_crypto_primary) ?? 0)} ${group.token_fiat}`,
                                type: QAItemType.TEXT,
                                content: group.question,
                                access: group.role_id !== null,
                            }))} />
                        )}
                    </>
                )
            )}
        </div>
    );
};

export default SearchPage;
