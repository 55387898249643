import React, { useEffect, useRef, useState } from 'react';
import { MdFormatUnderlined, MdFormatBold, MdFormatItalic, MdFormatSize, MdOutlineAttachFile, MdOutlineKeyboardVoice, MdDelete } from "react-icons/md";
import { Editor, EditorState, Modifier, RichUtils } from 'draft-js';

import './formatting-textarea.css';
import { Overlay, Tooltip } from 'react-bootstrap';
import { IoMdInformationCircleOutline } from 'react-icons/io';

export const EDITOR_CUSTOM_OPTIONS = {
    inlineStyles: {
        'MEDIUM': {
            style: {
                fontSize: '20px',
            },
        },
    },
};

const styleMap = {
    'MEDIUM': {
        fontSize: '20px',
    },
};

interface FormattingTextareaProps {
    style?: React.CSSProperties | undefined;
    editorState: EditorState;
    placeholder: string;
    label?: string | undefined;
    description?: string | undefined;
    buttonTitle?: string;
    showVoiceButton?: boolean;
    isLoading?: boolean;
    onVoiceClicked?: () => void;
    onSendClicked?: () => void;
    onEditorStateChange: (state: EditorState) => void;
    onFileAttached?: (attachment: File | null) => void;
}

const FormattingTextarea: React.FC<FormattingTextareaProps> = ({
    style,
    editorState,
    placeholder,
    label,
    description,
    buttonTitle,
    showVoiceButton = false,
    isLoading = false,
    onVoiceClicked,
    onSendClicked,
    onEditorStateChange,
    onFileAttached,
}) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    const hasStyle = (style: string) => currentStyle.has(style);

    const editorRef = useRef<Editor>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const iconRef = useRef(null);

    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [filePreview, setFilePreview] = useState<string | null>(null);
    const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        setIsPlaceholderVisible(!isFocused && !editorState.getCurrentContent().hasText())
    }, [editorState, isFocused])

    const handleKeyCommand = (command: string, state: EditorState) => {
        const newState = RichUtils.handleKeyCommand(state, command);
        if (newState) {
            onEditorStateChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    const onBoldClick = () => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
    };

    const onItalicClick = () => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
    };

    const onUnderlineClick = () => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = () => {
        setIsFocused(false);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            return;
        }

        onFileAttached?.(file);
        setUploadedFile(file);

        if (file.type.startsWith('image/')) {
            const filePreviewUrl = URL.createObjectURL(file);
            setFilePreview(filePreviewUrl);
        } else {
            setFilePreview(null);
        }
    };

    const handleIconClick = () => {
        fileInputRef.current?.click();
    };

    const applyTextSize = (size: string) => {
        const selection = editorState.getSelection();
        let nextContentState = editorState.getCurrentContent();

        // Remove all size styles
        Object.keys(styleMap).forEach(sizeKey => {
            if (hasStyle(sizeKey)) {
                nextContentState = Modifier.removeInlineStyle(nextContentState, selection, sizeKey);
            }
        });

        // Apply the new size if it's different from the current style
        if (size !== 'DEFAULT' && !hasStyle(size)) {
            nextContentState = Modifier.applyInlineStyle(nextContentState, selection, size);
        }

        const nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');
        onEditorStateChange(nextEditorState);
    };

    const focusEditor = () => {
        editorRef.current?.focus();
        setIsFocused(true);
    };

    const handleDeleteFile = () => {
        setUploadedFile(null);
        setFilePreview(null);
    };

    const onClickSend = () => {
        onSendClicked?.();
        handleDeleteFile();
    }
    return (
        <>
            <label className="profile-settings-label">
                {label}
                {description && (
                    <>
                        <span
                            ref={iconRef}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            className="d-none d-sm-inline-block ms-2"
                        >
                            <IoMdInformationCircleOutline className="info-icon" />
                        </span>
                        <Overlay
                            target={iconRef.current}
                            show={showTooltip}
                            placement="top"
                        >
                            <Tooltip
                                id={`tooltip-${label}`}
                            >
                                {description}
                            </Tooltip>
                        </Overlay>
                    </>
                )}
            </label>
            {description && (
                <span className="d-sm-none">
                    {/* Mobile view description */}
                    <p className="profile-settings-input-description">{description}</p>
                </span>
            )}
            {uploadedFile && (
                <div className="file-preview">
                    {filePreview ? (
                        <img src={filePreview} alt="Preview" className="file-preview-image" />
                    ) : (
                        <span className="file-preview-name">{uploadedFile.name}</span>
                    )}
                    <MdDelete onClick={handleDeleteFile} className="file-delete-icon" />
                </div>
            )}
            <div className="profile-settings-description">
                <div className="description-textarea" style={style}>
                    {isPlaceholderVisible && (
                        <div className="editor-placeholder" onClick={focusEditor}>{placeholder}</div>
                    )}
                    <Editor
                        ref={editorRef}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={handleKeyCommand}
                        onChange={onEditorStateChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        placeholder=""
                    />
                </div>
                <div className="description-icons">
                    <MdFormatUnderlined className="description-icon-btn" onClick={onUnderlineClick} />
                    <MdFormatBold className="description-icon-btn" onClick={onBoldClick} />
                    <MdFormatItalic className="description-icon-btn" onClick={onItalicClick} />
                    <MdFormatSize className="description-icon-btn" onClick={() => applyTextSize('MEDIUM')} />
                    <MdOutlineAttachFile className="description-icon-btn" onClick={handleIconClick} />
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </div>
                <div className="voice-save-container">
                    {showVoiceButton && !isLoading && (
                        <button className="btn btn-primary voice-btn" onClick={onVoiceClicked}>
                            <MdOutlineKeyboardVoice style={{ width: 20, height: 20 }} />
                        </button>
                    )}
                    {buttonTitle && (
                        isLoading ? (
                            <button className="btn btn-dark save-btn" disabled={isLoading}>
                                <div className="spinner-border spinner-border-sm" role="status" />
                            </button>
                        ) : (
                            <button className="btn btn-dark save-btn" disabled={isLoading || !editorState.getCurrentContent().hasText()} onClick={onClickSend}>
                                {buttonTitle}
                            </button>
                        )
                    )}
                </div>
            </div>
        </>
    );
};

export default FormattingTextarea;
