import api from "../utils/axiosInstance";

export interface ISkill {
  skill_id: number;
  name: string;
  avatar?: string;
  creation_date: string;
}

// Fetch skills using axios
export const fetchSkills = async (
  page?: number,
  pageSize?: number
): Promise<ISkill[]> => {
  try {
    // Set up query parameters
    const params: { [key: string]: any } = {};
    if (page !== undefined) {
      params.page = page;
    }
    if (pageSize !== undefined) {
      params.pageSize = pageSize;
    }

    const response = await api.get("/skills", { params });

    return response.data.skills;
  } catch (error) {
    console.error("Error fetching skills:", error);
    return [];
  }
};
