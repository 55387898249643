import React, { useCallback, useState } from 'react';
import { BsSearch, BsX } from "react-icons/bs";
import UserDefaultAvatar from '../../assets/images/default-user-avatar.jpg';
import './topbar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import useUserStore from '../../stores/userStore';
import useSearchStore from '../../stores/searchStore';
import LogoHorizontal from '../../assets/images/ask-a-dev-horizontal.png';

interface TopbarProps {
    isSidebarVisible: boolean;
    toggleSidebar: () => void;
}

const Topbar: React.FC<TopbarProps> = ({ isSidebarVisible, toggleSidebar }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const user = useUserStore((state) => state.user);

    // States
    const [mobileSearchActive, setMobileSearchActive] = useState<boolean>(false);

    // Stores
    const { keyword, lastPath, setKeyword, setLastPath } = useSearchStore();

    // Functions
    const handleSearchInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
        if (event.target.value.trim() !== '') {
            if (location.pathname !== '/search') {
                setLastPath(location.pathname);
                navigate(`/search`);
            }
        } else {
            if (lastPath) {
                navigate(lastPath);
                setLastPath(null);
            }
        }
    }, [location.pathname, lastPath]);

    const handleMobileSearchClick = () => {
        setMobileSearchActive(!mobileSearchActive);
    };

    const handleLogoClick = () => {
        navigate("/");
    };

    const handleUserAvatarClick = useCallback(() => {
        if (user) {
            navigate(`/dev/${user.userId}`);
        }
    }, [user]);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid d-flex justify-content-between">
                <div>
                    <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                        <span className="navbar-toggler-icon" style={{ display: isSidebarVisible ? 'none' : 'block' }}></span>
                        <span style={{ display: isSidebarVisible ? 'block' : 'none' }}>&times;</span>
                    </button>
                    <img
                        className="navbar-brand mb-0 h1 d-none d-lg-block"
                        style={{ cursor: "pointer", width: 150 }}
                        onClick={handleLogoClick}
                        src={LogoHorizontal}
                    />
                </div>

                <div className="d-flex align-items-center">
                    {/* Large screens */}
                    <div className="d-none d-lg-flex align-items-center">
                        <div className="search-container me-3">
                            <BsSearch className="search-icon" />
                            <input
                                className="search-input form-control"
                                type="search"
                                placeholder="Find Answers"
                                aria-label="Search"
                                value={keyword}
                                onChange={handleSearchInputChange}
                            />
                        </div>
                        {/*user && (
                        <button className="btn btn-outline-light text-nowrap profile-type-button me-3" type="submit">Switch to Selling</button>
                    )*/}
                    </div>
                    {/* Icons */}
                    {!mobileSearchActive ? (
                        <>
                            <img
                                className="navbar-brand d-block d-lg-none"
                                style={{ cursor: "pointer", width: 150, marginRight: 30 }}
                                onClick={handleLogoClick}
                                src={LogoHorizontal}
                            />
                            <BsSearch className="notification-icon ms-3 d-block d-lg-none" style={{ cursor: 'pointer' }} onClick={handleMobileSearchClick} />
                            {user && (
                                <>
                                    {/* NOTIFICATION ICON
                                <BsBell className="notification-icon ms-3" style={{ cursor: 'pointer' }} />
                                */}
                                    <img
                                        className="avatar ms-3"
                                        src={user.avatar ?? UserDefaultAvatar}
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleUserAvatarClick}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <div className="d-flex align-items-center d-block d-lg-none">
                            <div className="search-container me-3">
                                <BsSearch className="search-icon" />
                                <input
                                    className="search-input form-control"
                                    type="search"
                                    placeholder="Find Answers"
                                    aria-label="Search"
                                    value={keyword}
                                    onChange={handleSearchInputChange}
                                />
                            </div>
                            <BsX className="notification-icon" style={{ cursor: 'pointer' }} onClick={handleMobileSearchClick} />
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Topbar;
