import api from "../utils/axiosInstance";
import { IDev } from "./devService";
import { IGroup } from "./groupService";

export interface ISearch {
  devs: IDev[];
  groups: IGroup[];
}

// Fetch search results using axios
export const fetchSearchResults = async (
  userId: number | null,
  keyword: string
): Promise<ISearch | null> => {
  try {
    const params: { [key: string]: any } = { keyword };

    // Append userId to the parameters if provided
    if (userId !== null) {
      params.userId = userId;
    }

    const response = await api.get("/search", { params });

    return response.data as ISearch;
  } catch (error) {
    console.error("Error while searching for: " + keyword, error);
    return null;
  }
};
