import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import MessageBox from "../components/message-box/message-box";
import ProfileSettingsInput from "../components/profile-settings-input/profile-settings-input";
import { MdLogin } from "react-icons/md";
import { sendVerificationCode, verifyVerificationCode } from "../services/authService";
import { isValidEmail } from "../utils/helpers";
import { getUser } from "../services/userService";
import useUserStore from "../stores/userStore";

// LoginPage.tsx
const LoginPage = () => {
    const token = new URLSearchParams(useLocation().search).get("token");
    const { user, setUser } = useUserStore();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (token) {
            const checkVerificationCode = async () => {
                try {
                    setIsLoading(true);
                    setError(null);

                    const verified = await verifyVerificationCode(token);
                    if (verified) {
                        const user = await getUser();
                        if (user) {
                            setUser(user);
                        }
                    }
                } catch (error) {
                    console.log('Error while check verification code', error);
                    setError((error as Error).message);
                } finally {
                    setIsLoading(false);
                }
            }
            checkVerificationCode();
        }
    }, [token]);

    useEffect(() => {
        if (user) {
            navigate("/settings");
        }
    }, [user])

    const onClickSignIn = async () => {
        if (!isValidEmail(email)) return;

        try {
            setIsLoading(true);

            const sentEmail = await sendVerificationCode(email);
            setIsEmailSent(sentEmail);
        } catch (error) {
            console.log('Error while sending verification email', error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="page-container">
            <MessageBox
                title="Get paid by asking & answering questions"
                text="Receive tokens of developer, artist, influencer or creator by asking them questions. And you’re earning $ASK token for every USDC spent on interactions. It’s win-win. Start exploring with just one click."
            />
            {token ? (
                <div className="justify-content">
                    {error && (
                        <div className="email-verification-message">
                            <h3>Error</h3>
                            <p>{error}</p>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    {isEmailSent ? (
                        <div className="email-verification-message">
                            <h3>Check Your Email</h3>
                            <p>We've sent a verification link to <strong>{email}</strong>.<br></br>Please check your inbox and click on the link to proceed with your login.</p>
                        </div>
                    ) : (
                        <div className="row mt-5">
                            <div className="col-12 col-md-5 col-xxl-4">
                                <ProfileSettingsInput
                                    label="Email"
                                    placeholder="pixelninja@ask-a-dev.co"
                                    value={email}
                                    onChange={setEmail}
                                    disabled={isLoading}
                                />
                                {isLoading ? (
                                    <button className="btn btn-primary me-3 mb-3 btn-custom-purple w-100" disabled={isLoading}>
                                        <div className="spinner-border spinner-border-sm" role="status" />
                                    </button>
                                ) : (
                                    <button className="btn btn-primary me-3 mb-3 btn-custom-purple w-100" disabled={isLoading} onClick={onClickSignIn}>
                                        <MdLogin className="me-1" /> Sign In
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div >
    );
};

export default LoginPage;
