// discover-page.tsx
import './page.css';

import MessageBox from '../components/message-box/message-box';
import ItemsGrid from '../components/items-grid/items-grid';
import QABox, { QAItemType } from '../components/qa-box-grid/qa-box-grid';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchDiscoverData } from '../services/discoverService';
import useDiscoverStore from '../stores/discoverStore';
import { formatNumber } from '../utils/helpers';
import { SPACES_ENDPOINT } from '../utils/constants';
import useUserStore from '../stores/userStore';
import SkillItemsGrid from '../components/skill-items-grid/skill-items-grid';

const DiscoverPage = () => {
    const navigate = useNavigate();

    // Stores
    const {
        isInitialLoading,
        isLoading,
        skills,
        popularDevs,
        recentDevs,
        popularProjects,
        popularGroups,
        recentGroups,
        setIsInitialLoading,
        setIsLoading,
        setSkills,
        setPopularDevs,
        setRecentDevs,
        setPopularProjects,
        setPopularGroups,
        setRecentGroups,
    } = useDiscoverStore();
    const { user } = useUserStore();

    const handleArrowClick = (path: string) => {
        navigate(path);
    };

    useEffect(() => {
        const fetchAndSetSettings = async () => {
            setIsLoading(true);

            try {
                const discoverData = await fetchDiscoverData(user ? user.userId : null);
                if (discoverData) {
                    setSkills(discoverData.skills);
                    setPopularDevs(discoverData.popularDevs);
                    setRecentDevs(discoverData.recentDevs);
                    setPopularProjects(discoverData.popularProjects)
                    setPopularGroups(discoverData.popularGroups);
                    setRecentGroups(discoverData.recentGroups);
                    setIsInitialLoading(true);
                }
            } catch (error) {
                console.error("Error fetching discover data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchAndSetSettings();
    }, [user]);

    return (
        <div className="page-container">
            <MessageBox
                title="Ask question to people with skills"
                text="Chat and connect with developers, artists, influencers and your favourite creators. Pay a small fee per question or chat, and earn money when other users pay to view the answers to your question."
            />
            {!isInitialLoading && isLoading && (
                <div className="d-flex justify-content-center align-items-center mt-4">
                    <div className="spinner-border text-light" role="status" />
                </div>
            )}
            {skills.length > 0 && (
                <div className="mt-5">
                    <SkillItemsGrid
                        to=""
                        title="Search by Skills"
                        items={skills.map(skill => ({
                            to: `/devs?skillId=${skill.skill_id}&skillName=${skill.name}`,
                            imageUrl: skill.avatar,
                            title: skill.name,
                        }))}
                        onArrowClick={() => handleArrowClick('/skills')} />
                </div>
            )}
            {popularDevs.length > 0 && (
                <div className="mt-3">
                    <ItemsGrid to="" title="Popular Devs" items={popularDevs.map(dev => ({
                        to: `/dev/${dev.user_id}`,
                        imageUrl: dev.avatar ? `${SPACES_ENDPOINT}${dev.avatar}` : undefined,
                        title: dev.username ?? "Unknown",
                    }))} onArrowClick={() => handleArrowClick('/devs')} />
                </div>
            )}
            {recentDevs.length > 0 && (
                <div className="mt-3">
                    <ItemsGrid to="" title="Recent Devs" items={recentDevs.map(dev => ({
                        to: `/dev/${dev.user_id}`,
                        imageUrl: dev.avatar ? `${SPACES_ENDPOINT}${dev.avatar}` : undefined,
                        title: dev.username ?? "Unknown",
                    }))} onArrowClick={() => handleArrowClick('/devs')} />
                </div>
            )}
            {popularProjects.length > 0 && (
                <div className="mt-3">
                    <ItemsGrid to="" title="Popular Projects" items={popularProjects.map(dev => ({
                        to: `/dev/${dev.user_id}`,
                        imageUrl: dev.avatar ? `${SPACES_ENDPOINT}${dev.avatar}` : undefined,
                        title: dev.username ?? "Unknown",
                    }))} onArrowClick={() => handleArrowClick('/devs')} />
                </div>
            )}
            {popularGroups.length > 0 && (
                <div className="mt-3">
                    <QABox headline="Popular Questions" items={popularGroups.map(group => ({
                        group_id: group.group_id,
                        title: group.title,
                        price: `${formatNumber(Number(group.price_crypto_primary) ?? 0)} ${group.token_fiat}`,
                        type: QAItemType.TEXT,
                        content: group.question,
                        access: group.role_id !== null,
                    }))} onArrowClick={() => handleArrowClick('/qa')} />
                </div>
            )}
            {recentGroups.length > 0 && (
                <div className="mt-3">
                    <QABox headline="Recent Questions" items={recentGroups.map(group => ({
                        group_id: group.group_id,
                        title: group.title,
                        price: `${formatNumber(Number(group.price_crypto_primary) ?? 0)} ${group.token_fiat}`,
                        type: QAItemType.TEXT,
                        content: group.question,
                        access: group.role_id !== null,
                    }))} onArrowClick={() => handleArrowClick('/qa')} />
                </div>
            )}
        </div>
    );
};

export default DiscoverPage;
