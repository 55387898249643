// ChatMessageList.tsx
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import ChatMessageListItem from '../chat-message-list-item/chat-message-list-item';
import FormattingTextarea, { EDITOR_CUSTOM_OPTIONS } from '../formatting-textarea/formatting-textarea';
import VoiceMessageInput from '../chat-message-list-item/voice-message-input/voice-message-input';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Dropdown } from 'react-bootstrap';
import { IGroupMessage, markGroupAsCompleted, createGroupMessage, fetchGroupMessages } from '../../services/groupService';
import { SPACES_ENDPOINT } from '../../utils/constants';
import useUserStore from '../../stores/userStore';
import { IChat } from '../../services/userService';
import UserDefaultAvatar from '../../assets/images/default-user-avatar.jpg';
import useAppStore from '../../stores/appStore';
import SelectPaymentModal from '../../modals/select-payment-modal/select-payment-modal';
import { formatAmount } from '../../utils/helpers';
import SelectPaymentQuestionModal from '../../modals/select-payment-question-modal/select-payment-qeuestion-modal';
import { useLocation, useNavigate } from 'react-router-dom';

import './chat-message-list.css';

interface ChatMessageListProps {
    selectedChat?: IChat | undefined,
    onResetSelectedChat: () => void;
}

const ChatMessageList: React.FC<ChatMessageListProps> = ({ selectedChat, onResetSelectedChat }) => {
    const location = useLocation();
    const navigate = useNavigate();

    // References
    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    // States
    const [attachmentFile, setAttachmentFile] = useState<File | null>(null);
    const [messages, setMessages] = useState<IGroupMessage[]>([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [showVoiceChat, setShowVoiceChat] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSentChatMessageLoading, setIsSentChatMessageLoading] = useState<boolean>(false);
    const [showSelectPaymentModal, setShowSelectPaymentModal] = useState(false);
    const [showPayMessageModal, setShowPayMessageModal] = useState<number | null>(null);
    const [voiceFile, setVoiceFile] = useState<File | undefined>(undefined);

    // Stores
    const { showAlertModal } = useAppStore();
    const { user } = useUserStore();

    useEffect(() => {
        const fetchMessages = async () => {
            if (selectedChat) {
                try {
                    setIsLoading(true);
                    const messages = await fetchGroupMessages(selectedChat.group_id, undefined, undefined);
                    setMessages(messages);
                    setTimeout(() => {
                        if (messagesEndRef.current) {
                            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
                        }
                    }, 100);
                } catch (error) {
                    console.log('Error while fetching messages:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        }
        fetchMessages();
    }, [selectedChat]);
    
    const onClickPay = useCallback(async () => {
        if (user) {
            setShowSelectPaymentModal(true);
        } else {
            navigate("/login");
        }
    }, [user]);

    const onClickVoiceSend = useCallback(async (
        audioFile: File | undefined,
        paymentReference?: string | undefined
    ) => {
        if (selectedChat?.group_id) {
            try {
                setShowPayMessageModal(null);
                setIsSentChatMessageLoading(true);

                const createdMessage = await createGroupMessage(selectedChat.group_id, "", audioFile ?? voiceFile, paymentReference);
                if (createdMessage) {
                    setVoiceFile(undefined);
                    setMessages([...messages, createdMessage]);
                    setTimeout(() => {
                        if (messagesEndRef.current) {
                            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
                        }
                    }, 100);
                }
            } catch (error) {
                console.log('Error while creating group audio message:', error);
            } finally {
                setIsSentChatMessageLoading(false);
                setShowVoiceChat(false);
            }
        }
    }, [selectedChat, voiceFile, messages]);

    const onClickSendMessage = useCallback(async (
        paymentReference?: string | undefined
    ) => {
        if (selectedChat) {
            try {
                setShowPayMessageModal(null);
                setIsSentChatMessageLoading(true);

                const message = stateToHTML(editorState.getCurrentContent(), EDITOR_CUSTOM_OPTIONS);
                const createdMessage = await createGroupMessage(selectedChat.group_id, message, attachmentFile ?? undefined, paymentReference);
                if (createdMessage) {
                    setMessages([...messages, createdMessage]);
                    setAttachmentFile(null);
                    setEditorState(EditorState.createEmpty());
                    setTimeout(() => {
                        if (messagesEndRef.current) {
                            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
                        }
                    }, 100);
                }
            } catch (error) {
                console.log('Error while creating group message:', error);
            } finally {
                setIsSentChatMessageLoading(false);
            }
        }
    }, [selectedChat, editorState, attachmentFile]);

    const onClickViewAnswererProfile = useCallback(() => {
        if (selectedChat) {
            window.open(`/dev/${selectedChat.answerer.user_id}`, '_blank');
        }
    }, [selectedChat]);

    const onClickViewAskerProfile = useCallback(() => {
        if (selectedChat) {
            window.open(`/dev/${selectedChat.asker.user_id}`, '_blank');
        }
    }, [selectedChat]);


    const onClickMarkAsCompleted = useCallback(async () => {
        if (selectedChat) {
            try {
                setIsLoading(true);
                await markGroupAsCompleted(selectedChat.group_id);

                // Refresh chat page
                const params = new URLSearchParams(location.search);
                const id = params.get('id');
                if (id) {
                    window.location.reload();
                } else {
                    params.append('id', selectedChat.group_id.toString());
                    window.location.href = `${location.pathname}?${params.toString()}`;
                }
            } catch (error) {
                console.log('Error while mark question as completed:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [selectedChat]);

    return (
        <div className="chat-message-list">
            {selectedChat ? (
                <>
                    <div className="header d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center ms-3">
                            {selectedChat && (
                                <MdOutlineArrowBackIosNew
                                    className="text-light me-3 d-block d-lg-none"
                                    style={{ cursor: "pointer" }}
                                    onClick={onResetSelectedChat}
                                />
                            )}
                            {selectedChat.role_id === 1 ? (
                                <div
                                    className="d-flex align-items-center"
                                    style={{ cursor: "pointer" }}
                                    onClick={onClickViewAskerProfile}
                                >
                                    <div className="user-avatar">
                                        <img src={selectedChat.asker.avatar ? `${SPACES_ENDPOINT}${selectedChat.asker.avatar}` : UserDefaultAvatar} alt={selectedChat.asker.username} />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h4 className="chat-message-list-user-name">{selectedChat.asker.username ?? "Unknown"}</h4>
                                        <p className="chat-message-list-user-role">Asker</p>
                                        {/*<p
                                        className="chat-message-list-status"
                                        style={{ color: chat.online ? '#48EB4E' : '#B1B5C1' }}
                                    >
                                        {chat.online ? '· Online' : '· Offline'}
                                    </p>*/}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="d-flex align-items-center"
                                    style={{ cursor: "pointer" }}
                                    onClick={onClickViewAnswererProfile}
                                >
                                    <div className="user-avatar">
                                        <img src={selectedChat.answerer.avatar ? `${SPACES_ENDPOINT}${selectedChat.answerer.avatar}` : UserDefaultAvatar} alt={selectedChat.answerer.username} />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h4 className="chat-message-list-user-name">{selectedChat.answerer.username ?? "Unknown"}</h4>
                                        <p className="chat-message-list-user-role">Developer</p>
                                        {/*<p
                                        className="chat-message-list-status"
                                        style={{ color: chat.online ? '#48EB4E' : '#B1B5C1' }}
                                    >
                                        {chat.online ? '· Online' : '· Offline'}
                                    </p>*/}
                                    </div>
                                </div>
                            )}
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle variant="" className="clickable-icon me-2 chat-category-dropdown-menu-toggle" id="dropdown-basic">
                                <BsThreeDotsVertical className="clickable-icon me-2" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="chat-category-dropdown-menu">
                                <Dropdown.Item onClick={onClickViewAnswererProfile}>View Developer Profile</Dropdown.Item>
                                {selectedChat.group_type === 1 && selectedChat.asker.user_id !== user?.userId && (
                                    <Dropdown.Item onClick={onClickViewAskerProfile}>View Asker Profile</Dropdown.Item>
                                )}
                                {selectedChat.group_type === 1 && selectedChat.answerer.user_id === user?.userId && !selectedChat.completed && (
                                    <Dropdown.Item onClick={onClickMarkAsCompleted}>Mark As Completed</Dropdown.Item>
                                )}
                                {/*<Dropdown.Item onClick={() => { alert("Delete chat functionality coming soon!") }}>Delete Chat</Dropdown.Item>*/}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="chat-message-list-divider mt-2 mb-2" />
                    <div className="chat-messages-container" ref={messagesEndRef}>

                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border text-light" role="status" />
                            </div>
                        ) : (
                            messages.length > 0 ? (
                                messages.map((message, index) => (
                                    <ChatMessageListItem
                                        key={index}
                                        groupId={selectedChat.group_id}
                                        messageId={message.message_id}
                                        message={message.message_text}
                                        avatar={message.avatar ? `${SPACES_ENDPOINT}${message.avatar}` : undefined}
                                        dateTime={message.sent_at}
                                        filePath={message.attachment?.file_path ? `${SPACES_ENDPOINT}${message.attachment?.file_path}` : undefined}
                                        fileType={message.attachment?.file_type}
                                        isOwnMessage={user?.userId === message.user_id}
                                        isMessageLocked={selectedChat.role_id == null && index > 0}
                                    />
                                ))
                            )
                                : (
                                    selectedChat.role_id === 5 ? (
                                        <>
                                            <p className="text-light text-center">This is the beginning of your very own group chat.</p>
                                            <p className="text-light text-center">Set the price for users to join your group chat in profile settings.</p>
                                        </>
                                    ) : (
                                        <p className="text-light text-center">No messages available</p>
                                    )
                                )
                        )}
                    </div>
                    {!selectedChat.role_id ? (
                        <div className="ps-4 pe-4">
                            <button className="btn btn-primary me-3 w-100 btn-chat-pay mb-2 mt-3" onClick={onClickPay}>
                                Pay 1$ to See the Answer
                            </button>
                        </div>
                    ) : (
                        selectedChat.role_id !== 3 && !selectedChat.completed && (
                            <div className="mt-3 ps-4 pe-4 chat-input-message-container">
                                {showVoiceChat ? (
                                    <VoiceMessageInput
                                        amount={undefined}
                                        isLoading={isSentChatMessageLoading}
                                        onCancelClicked={() => setShowVoiceChat(false)}
                                        onSendClicked={(audioFile: File) => {
                                            onClickVoiceSend(audioFile);
                                        }}
                                    />
                                ) : (
                                    <FormattingTextarea
                                        style={{ height: "auto", minHeight: 100 }}
                                        editorState={editorState}
                                        placeholder="Type a message..."
                                        buttonTitle={"Send"}
                                        showVoiceButton
                                        isLoading={isSentChatMessageLoading}
                                        onVoiceClicked={() => setShowVoiceChat(true)}
                                        onSendClicked={() => {
                                            onClickSendMessage();
                                        }}
                                        onEditorStateChange={setEditorState}
                                        onFileAttached={setAttachmentFile}
                                    />
                                )}
                            </div>
                        )
                    )}
                </>
            ) : (
                <div className="d-flex justify-content-center align-items-center h-100">
                    <p className="p-3 text-light no-chat-selected-message">Select a chat to view answers</p>
                </div>
            )}
            {selectedChat && showSelectPaymentModal && (
                <SelectPaymentModal
                    group_id={selectedChat.group_id}
                    dev_id={selectedChat.answerer.user_id}
                    onPaymentDone={() => {
                        showAlertModal("Payment successful!", "You have now access to the chat.");
                        window.location.reload();
                    }}
                    onClose={() => {
                        setShowSelectPaymentModal(false);
                    }}
                />
            )}
            {showPayMessageModal && selectedChat && selectedChat.price && selectedChat.price > 0 && (
                <SelectPaymentQuestionModal
                    amount={selectedChat.price}
                    dev_id={selectedChat.answerer.user_id}
                    onPaymentDone={(reference: string | undefined) => {
                        if (showPayMessageModal === 1) {
                            onClickSendMessage(reference);
                        } else if (showPayMessageModal === 2) {
                            onClickVoiceSend(undefined, reference);
                        }
                    }}
                    onClose={() => {
                        setShowPayMessageModal(null);
                    }}
                />
            )}
        </div>
    );
};

export default ChatMessageList;
