import { create } from "zustand";
import { IUserSettings } from "../services/userSettingsService";
import { ISocial } from "../services/socialService";
import { ISkill } from "../services/skillService";

interface UserSettingsState {
  userSettings: IUserSettings | null;
  setUserSettings: (userSettings: IUserSettings) => void;
  addSocial: (social: ISocial) => void;
  removeSocial: (socialId: number) => void;
  addSkill: (skill: ISkill) => void;
  removeSkill: (skillId: number) => void;
  removeUserSettings: () => void;
}

const useUserSettingsStore = create<UserSettingsState>((set) => ({
  userSettings: null,
  setUserSettings: (userSettings: IUserSettings) =>
    set({ userSettings: userSettings }),
  addSocial: (social: ISocial) =>
    set((state) => ({
      userSettings: state.userSettings
        ? {
            ...state.userSettings,
            socials: state.userSettings.socials
              ? [...state.userSettings.socials, social]
              : [social],
          }
        : null,
    })),
  removeSocial: (socialId: number) =>
    set((state) => ({
      userSettings: state.userSettings
        ? {
            ...state.userSettings,
            socials: state.userSettings.socials
              ? state.userSettings.socials.filter(
                  (social) => social.social_id !== socialId
                )
              : [],
          }
        : null,
    })),
  addSkill: (skill: ISkill) =>
    set((state) => ({
      userSettings: state.userSettings
        ? {
            ...state.userSettings,
            skills: state.userSettings.skills
              ? [...state.userSettings.skills, skill]
              : [skill],
          }
        : null,
    })),
  removeSkill: (skillId: number) =>
    set((state) => ({
      userSettings: state.userSettings
        ? {
            ...state.userSettings,
            skills: state.userSettings.skills
              ? state.userSettings.skills.filter(
                  (skill) => skill.skill_id !== skillId
                )
              : [],
          }
        : null,
    })),
  removeUserSettings: () => set({ userSettings: null }),
}));

export default useUserSettingsStore;
