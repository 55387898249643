import React, { CSSProperties, useRef, useState } from 'react';
import { BsPlus, BsX } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { ISkill } from '../../services/skillService';

import './skill-list.css';
import { Overlay, Tooltip } from 'react-bootstrap';
import { IoMdInformationCircleOutline } from 'react-icons/io';

interface SkillListProps {
    headlineStyle?: CSSProperties | undefined;
    headline: string;
    description?: string | undefined;
    skills: ISkill[];
    addTitle?: string;
    onAddClick?: () => void;
    onClickRemove?: (skill: ISkill) => void;
}

const SkillList: React.FC<SkillListProps> = ({ headlineStyle, headline, description, skills, addTitle, onAddClick, onClickRemove }) => {
    const navigate = useNavigate();

    // References
    const iconRef = useRef(null);

    // States
    const [showTooltip, setShowTooltip] = useState(false);

    const onClickSkill = (skillId: number, skillName: string) => {
        navigate(`/devs?skillId=${skillId}&skillName=${skillName}`);
    }

    return (
        <>
            <h4 style={headlineStyle} className="skill-list-headline">
                {headline}
                {description && (
                    <>
                        <span
                            ref={iconRef}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            className="d-none d-sm-inline-block ms-2"
                        >
                            <IoMdInformationCircleOutline className="info-icon" />
                        </span>
                        <Overlay
                            target={iconRef.current}
                            show={showTooltip}
                            placement="top"
                        >
                            <Tooltip
                                id={`tooltip-${headline}`}
                            >
                                {description}
                            </Tooltip>
                        </Overlay>
                    </>
                )}
            </h4>
            {description && (
                <span className="d-sm-none">
                    {/* Mobile view description */}
                    <p className="profile-settings-input-description">{description}</p>
                </span>
            )}
            <div className="skill-list">
                {skills.map((skill, index) => (
                    <div className="skill-item-container" key={index}>
                        <div className="skill-item" onClick={() => onClickSkill(skill.skill_id, skill.name)}>
                            {skill.name}
                        </div>
                        {onClickRemove && (
                            <BsX className="delete-skill-icon" onClick={() => onClickRemove(skill)} />
                        )}
                    </div>
                ))}
                {onAddClick && addTitle && (
                    <div className="skill-item add-skill" onClick={onAddClick}>
                        <BsPlus className="add-icon" />
                        {addTitle}
                    </div>
                )}
            </div>
        </>
    );
};

export default SkillList;
