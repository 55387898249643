import './select-social-modal.css';

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useUserSettingsStore from '../../stores/userSettingsStore';
import { ISocial, fetchSocials } from '../../services/socialService';
import { API_URL } from '../../utils/constants';

interface ModalProps {
    onClose: (updated: boolean) => void;
}

const SelectSocialModal: React.FC<ModalProps> = ({ onClose }) => {
    // Stores
    const { userSettings } = useUserSettingsStore();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedSocial, setSelectedSocial] = useState<ISocial | null>(null);
    const [socials, setSocials] = useState<ISocial[]>([]);

    useEffect(() => {
        const fetchAvailableSkills = async () => {
            try {
                setIsLoading(true);

                const userSocials = userSettings?.socials ?? [];
                const availableSocials = await fetchSocials();
                const filteredSocials = availableSocials.filter(social => {
                    return !userSocials.some(userSocial => userSocial.social_id === social.social_id);
                });
                setSocials(filteredSocials);
            } catch (error) {
                console.log('Error while fetching available socials', error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchAvailableSkills();
    }, [])

    const onHandleAddSocial = async () => {
        if (selectedSocial) {
            try {
                setIsLoading(true);

                // Twitter
                if (selectedSocial.social_id === 1) {
                    window.location.href = `${API_URL}/auth/twitter`;
                }
            } catch (error) {
                console.log('Error while adding a new social', error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    // Custom styles for React Select
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: '#131313',
            border: 'none',
            borderRadius: '12px',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            boxShadow: 'none',
            color: 'white',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#6033FF' : 'transparent',
            color: 'white',
            cursor: 'pointer',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#B1B5C1',
            opacity: 1,
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: 'white',
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: '#131313',
        }),
    };

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title">Add New Social</h3>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border text-light" role="status" />
                    </div>
                ) : (
                    <>
                        <Select
                            classNamePrefix="react-select"
                            className="input-modal-select mt-4"
                            options={socials.map(social => ({ value: social.name, label: social.name }))}
                            value={selectedSocial ? { value: selectedSocial.name, label: selectedSocial.name } : null}
                            onChange={(selectedOption) => setSelectedSocial(selectedOption ? socials.find(social => social.name === selectedOption.value) || null : null)}
                            placeholder="Select a social"
                            styles={customStyles}
                        />
                        <div className="text-end mt-4">
                            <button className="btn btn-primary add-btn me-2" onClick={onHandleAddSocial} disabled={selectedSocial === null}>Link {selectedSocial?.name}</button>
                            <button className="btn btn-light cancel-btn" onClick={() => onClose(false)}>Close</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SelectSocialModal;
