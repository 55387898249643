import React from 'react';
import solanaIcon from '../../assets/icons/solana-pay.png';

import './solana-pay.css';

type SolanaPayProps = {
    className?: string | undefined;
    onClick: () => void;
};

const SolanaPay: React.FC<SolanaPayProps> = ({ className, onClick }) => {
    return (
        <button className={`${className} solana-pay-btn`} onClick={onClick}>
            Pay with
            <img src={solanaIcon} alt="Solana Pay" className="solana-icon" />
        </button>
    );
};

export default SolanaPay;
