import React from 'react';
import { BsLock, BsShare, BsArrowRight } from 'react-icons/bs';
import './qa-box-grid.css';
import VoiceMessage from '../voice-message/voice-message';
import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router-dom';
import useAppStore from '../../stores/appStore';
import { access } from 'fs';

export enum QAItemType {
    TEXT,
    AUDIO
}

interface QAItem {
    group_id: number;
    title: string;
    price: string;
    type: QAItemType;
    content?: string | undefined;
    url?: string | undefined;
    access: boolean;
}

interface QABoxProps {
    headline?: string | undefined;
    items: QAItem[];
    onArrowClick?: () => void;
}

const QABox: React.FC<QABoxProps> = ({ headline, items, onArrowClick }) => {
    const navigate = useNavigate();

    const { showAlertModal } = useAppStore();

    const handleShareClick = async (groupId: number) => {
        const urlToShare = `${window.location.protocol}//${window.location.host}/messages?id=${groupId}`;

        // Check if the Web Share API is available
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share this link',
                    url: urlToShare,
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            try {
                await navigator.clipboard.writeText(urlToShare);
                showAlertModal("QA Copied!", "The link has been copied to your clipboard.");
            } catch (error) {
                console.error('Error copying to clipboard:', error);
            }
        }
    };

    const onClickGetAccess = (groupId: number) => {
        navigate(`/messages?id=${groupId}`);
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                {headline && headline.trim().length > 0 && (
                    <h2 className="text-light qa-grid-title">{headline}</h2>
                )}
                {onArrowClick && (
                    <BsArrowRight className="text-light" onClick={onArrowClick} style={{ cursor: 'pointer' }} />
                )}
            </div>
            <div className="row flex-grow-1">
                {items.map((item, index) => (
                    <div className="col-12 col-lg-6 col-xxl-4 mb-4 d-flex" key={index}>
                        <div className="qa-box p-3 d-flex flex-column justify-content-between">
                            <div>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h3 className="qa-title me-auto">{item.title}</h3>
                                    <div className="d-flex align-items-center">
                                        <div className="price-box text-nowrap d-none d-md-block">
                                            {item.price}
                                        </div>
                                        <div className="ms-2 share-icon d-md-none" onClick={() => handleShareClick(item.group_id)}>
                                            <BsShare color="white" />
                                        </div>
                                    </div>
                                </div>

                                {item.type === QAItemType.TEXT && item.content && (
                                    <div className="qa-text mb-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.content.length > 200 ? `${item.content.substring(0, 200)}...` : item.content) }} />
                                )}

                                {item.type === QAItemType.AUDIO && item.url && (
                                    <div className="mb-3">
                                        <VoiceMessage
                                            audioUrl={item.url}
                                            avatarUrl="https://mpost.io/wp-content/uploads/image-46-153.jpg"
                                            creationDate="Dec 02, 2023, 5:12 PM"
                                        />
                                    </div>
                                )}
                            </div>

                            <div>
                                <div className="d-flex justify-content-start d-none d-md-block mb-2">
                                    <button className="btn btn-primary me-3 btn-get-access" onClick={() => onClickGetAccess(item.group_id)}>
                                        {item.access ? "View Chat" : "Get Access for 1$"}
                                    </button>
                                    <button className="btn btn-primary btn-share" onClick={() => handleShareClick(item.group_id)}>
                                        <BsShare className="me-1" /> Share
                                    </button>
                                </div>

                                <button className="btn btn-primary me-3 w-100 btn-get-access d-md-none mb-2" onClick={() => onClickGetAccess(item.group_id)}>
                                    Get Access for 1$
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default QABox;
