import { TransferRequestURL } from "@solana/pay";
import { PublicKey } from "@solana/web3.js";
import api from "../utils/axiosInstance";

// Create a payment link
export const createPaymentLink = async (
  dev_user_id: number,
  intent_type: "answer" | "question" | "group"
): Promise<string | null> => {
  try {
    const response = await api.post("/payments/payment-link", {
      dev_user_id,
      intent_type,
    });

    return response.status === 201 ? response.data.url : null;
  } catch (error) {
    console.error("Error during the API call:", error);
    return null;
  }
};

// Verify payment
export const verifyPayment = async (reference: string): Promise<boolean> => {
  try {
    const response = await api.post("/payments/verify-payment", {
      reference,
    });

    return response.status === 200 ? response.data.paymentVerified : false;
  } catch (error) {
    console.error("Error during the API call:", error);
    return false;
  }
};

export interface PaymentTransaction {
  transaction: string;
  message: string;
}

// Create a payment transaction
export const createPaymentTransaction = async (
  transactionRequest: TransferRequestURL,
  wallet: PublicKey
): Promise<PaymentTransaction | null> => {
  try {
    const query = new URLSearchParams({
      recipient: transactionRequest.recipient.toString(),
      amount: transactionRequest.amount!.toString(),
      reference: transactionRequest.reference![0].toString(),
    });

    if (transactionRequest.splToken) {
      query.set("spl-token", transactionRequest.splToken.toString());
    }

    if (transactionRequest.memo) {
      query.set("memo", transactionRequest.memo);
    }

    if (transactionRequest.message) {
      query.set("message", transactionRequest.message);
    }

    const response = await api.post(
      `/payments/payment-transaction?${query.toString()}`,
      {
        account: wallet.toString(),
      }
    );

    return response.status === 201 ? response.data : null;
  } catch (error) {
    console.error("Error during the API call:", error);
    return null;
  }
};
