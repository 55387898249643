import React from 'react';
import useAppStore from '../../stores/appStore';

import './alert-modal.css';

export interface AlertModalSettings {
    title: string;
    message: string;
}

interface ModalProps {
    settings: AlertModalSettings;
}

const AlertModal: React.FC<ModalProps> = ({ settings }) => {
    const { hideAlertModal } = useAppStore();

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{settings.title}</h3>
                <p className="text-light">{settings.message}</p>
                <div className="text-end mt-4">
                    <button className="btn btn-light cancel-btn" onClick={hideAlertModal}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default AlertModal;
