import React, { useState } from 'react';
import ProfileSettingsInput from '../../components/profile-settings-input/profile-settings-input';
import FormattingTextarea, { EDITOR_CUSTOM_OPTIONS } from '../../components/formatting-textarea/formatting-textarea';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import './ask-question-modal.css';

interface ModalProps {
    dev_userId: number;
    onStartPayment: (title: string, question: string) => void;
    onClose: () => void;
}

const AskQuestionModal: React.FC<ModalProps> = ({ dev_userId, onStartPayment, onClose }) => {
    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onHandleAskQuestion = async () => {
        const question = stateToHTML(editorState.getCurrentContent(), EDITOR_CUSTOM_OPTIONS);
        if (title.length > 0 && question.length > 0) {
            onStartPayment(title, question);
        }
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">Create Question</h3>
                <ProfileSettingsInput
                    label="Titel"
                    placeholder="Enter question title"
                    value={title}
                    onChange={(e) => setTitle(e)}
                />
                <FormattingTextarea
                    editorState={editorState}
                    placeholder="Enter your question"
                    label="Question"
                    onEditorStateChange={setEditorState}
                />
                <div className="text-end mt-4">
                    <button className={`btn btn-primary add-btn me-2 ${isLoading ? 'disabled' : ''}`} onClick={onHandleAskQuestion} disabled={isLoading || title.length <= 0 || !editorState.getCurrentContent().hasText()}>
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        ) : (
                            'Proceed With Payment'
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-light cancel-btn" onClick={onClose}>Close</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AskQuestionModal;
