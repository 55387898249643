import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import DefaultUserAvatar from '../../assets/images/default-user-avatar.jpg';

import './items-grid.css';

interface Item {
    to?: string;
    imageUrl?: string;
    title: string;
}

interface ItemsGridProps {
    to: string;
    title?: string;
    items: Item[];
    onArrowClick?: () => void;
}

const ItemsGrid: React.FC<ItemsGridProps> = ({ to, title, items, onArrowClick }) => {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                {title && title.trim().length > 0 && (
                    <h2 className="text-light item-grid-title">{title}</h2>
                )}
                {onArrowClick && (
                    <BsArrowRight className="text-light" onClick={onArrowClick} style={{ cursor: 'pointer' }} />
                )}
            </div>
            <div className="row custom-xxl-grid">
                {items.map((item, index) => (
                    <div className="col-6 col-md-3 col-xl-2 col-xxl-1 mb-4" key={index}>
                        <div className="item">
                            <div className="square-image">
                                <Link to={item.to ?? to}>
                                    <img src={item.imageUrl ?? DefaultUserAvatar} alt={item.title} className="img-fluid" />
                                </Link>
                            </div>
                            <h4 className="mt-2">{item.title}</h4>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ItemsGrid;
