import api from "../utils/axiosInstance";

// Send a verification code to the provided email address
export const sendVerificationCode = async (email: string): Promise<boolean> => {
  try {
    const response = await api.post("/auth/", { email });

    return response.status === 201;
  } catch (error) {
    console.error("Error during the API call:", error);
    return false;
  }
};

// Verify the verification code using the provided token
export const verifyVerificationCode = async (
  token: string
): Promise<boolean> => {
  try {
    const response = await api.get("/auth/verify", {
      params: { token },
    });

    if (response.status === 201) {
      const data = response.data;

      // If the tokens are in the response body, store them in localStorage
      if (data.accessToken && data.refreshToken) {
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);

        // Set the Authorization header for future requests
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.accessToken}`;
      }

      return true;
    }

    return false;
  } catch (error) {
    console.error("Error during the API call:", error);
    return false;
  }
};

// Log out the user
export const logout = async (): Promise<boolean> => {
  try {
    const response = await api.post("/auth/logout");

    if (response.status === 200) {
      // Clear tokens from localStorage on logout
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }

    return response.status === 200;
  } catch (error) {
    console.error("Error during the API call:", error);
    return false;
  }
};
