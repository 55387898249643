import React from 'react';
import './message-box.css';
import MessageBoxBackground from '../../assets/images/message-box-background.png';

interface MessageBoxProps {
    title: string;
    text: string;
}

const MessageBox: React.FC<MessageBoxProps> = ({ title, text }) => {
    return (
        <div 
            className="message-box" 
            style={{ backgroundImage: `url(${MessageBoxBackground})` }}
        >
            <h3 className="fw-bold">{title}</h3>
            <p className="message-text">{text}</p>
        </div>
    );
};

export default MessageBox;
