import { TransferRequestURL, encodeURL } from "@solana/pay";

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const formatNumber = (num: number): string => {
  const formattedNum = num.toFixed(2);
  return parseFloat(formattedNum).toString();
};

export const calculateTimeAgo = (isoDate: string): string => {
  const sentTime = new Date(isoDate);
  const now = new Date();
  const deltaSeconds = Math.floor((now.getTime() - sentTime.getTime()) / 1000);

  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  if (deltaSeconds < minute) {
    return deltaSeconds < 10 ? "just now" : `${deltaSeconds} seconds ago`;
  } else if (deltaSeconds < hour) {
    const minutes = Math.floor(deltaSeconds / minute);
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (deltaSeconds < day) {
    const hours = Math.floor(deltaSeconds / hour);
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else if (deltaSeconds < week) {
    const days = Math.floor(deltaSeconds / day);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (deltaSeconds < month) {
    const weeks = Math.floor(deltaSeconds / week);
    return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
  } else if (deltaSeconds < year) {
    const months = Math.floor(deltaSeconds / month);
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  } else {
    const years = Math.floor(deltaSeconds / year);
    return `${years} year${years !== 1 ? "s" : ""} ago`;
  }
};

export const formatDateTime = (dateStr: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: false,
  };

  const date = new Date(dateStr);
  return date.toLocaleDateString("en-US", options);
};

export const formatAmount = (amount: number | string): string => {
  const numericAmount =
    typeof amount === "number" ? amount : parseFloat(amount);

  if (isNaN(numericAmount)) {
    return "Invalid number";
  }

  let formatted = numericAmount.toFixed(2);

  formatted = formatted.replace(/\.00$/, "");
  formatted = formatted.replace(/(\.\d)0$/, "$1");

  return formatted;
};

export const buildPaymentQuery = (
  transferRequestUrl: TransferRequestURL
): URLSearchParams => {
  const query = new URLSearchParams({
    label: transferRequestUrl.label ?? "",
    message: transferRequestUrl.message ?? "",
    recipient: transferRequestUrl.recipient.toString(),
    amount: transferRequestUrl.amount?.toString() ?? "",
    reference: transferRequestUrl.reference?.[0].toString() ?? "",
  });
  if (transferRequestUrl.splToken) {
    query.set("spl-token", transferRequestUrl.splToken.toString());
  }
  if (transferRequestUrl.memo) {
    query.set("memo", transferRequestUrl.memo);
  }
  return query;
};

export const buildTransactionURL = (query: URLSearchParams): URL => {
  const transactionURL = `https://api.ask-a-dev.co/payments/payment-transaction?${query.toString()}`;
  return encodeURL({
    link: new URL(transactionURL),
    label: "Ask A Dev",
    message: "Create Question",
  });
};
