import { SPACES_ENDPOINT } from "../utils/constants";
import { ISocial } from "./socialService";
import { ISkill } from "./skillService";
import api from "../utils/axiosInstance";

export interface IUser {
  userId: number;
  publicKey: string;
  email: string;
  creationDate: string;
  avatar?: string;
}

export interface IChatUser {
  user_id: number;
  avatar?: string;
  username?: string;
}

export interface IChat {
  group_id: number;
  role_id: number;
  lastMessageUser: IChatUser;
  answerer: IChatUser;
  asker: IChatUser;
  message_text: string;
  sent_at: string;
  price?: number;
  group_type: number;
  completed: boolean;
}

// Fetch user data
export const getUser = async (): Promise<IUser | null> => {
  try {
    const response = await api.get("/user", { withCredentials: true });

    if (response.status === 200) {
      const data = response.data;
      return {
        userId: data.user_id,
        publicKey: data.public_key,
        email: data.email,
        creationDate: data.creation_date,
        avatar: data.avatar ? `${SPACES_ENDPOINT}${data.avatar}` : undefined,
      };
    } else {
      console.error("Verification failed:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error during the API call:", error);
    return null;
  }
};

// Add skill to user
export const addSkill = async (skillId: number): Promise<ISkill | null> => {
  try {
    const response = await api.post(
      "/user/skills",
      { skillId },
      { withCredentials: true }
    );

    if (response.status === 201) {
      return response.data.skill;
    } else {
      console.error("Failed to add skill:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error adding skill:", error);
    return null;
  }
};

// Remove skill from user
export const removeUserSkill = async (skillId: number): Promise<boolean> => {
  try {
    const response = await api.delete(`/user/skills/${skillId}`, {
      withCredentials: true,
    });
    return response.status === 200;
  } catch (error) {
    console.error("Error removing skill:", error);
    return false;
  }
};

// Add social to user
export const addSocial = async (
  socialId: number,
  value: string
): Promise<ISocial | null> => {
  try {
    const response = await api.post(
      "/user/socials",
      { socialId, value },
      { withCredentials: true }
    );

    if (response.status === 201) {
      return response.data.social;
    } else {
      console.error("Failed to add social:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error adding social:", error);
    return null;
  }
};

// Remove social from user
export const removeUserSocial = async (socialId: number): Promise<boolean> => {
  try {
    const response = await api.delete(`/user/socials/${socialId}`, {
      withCredentials: true,
    });
    return response.status === 200;
  } catch (error) {
    console.error("Error removing social:", error);
    return false;
  }
};

// Fetch user group list
export const fetchUserGroupList = async (
  page?: number,
  pageSize?: number,
  roleId?: number | number[],
  answered?: boolean
): Promise<IChat[]> => {
  try {
    const params: { [key: string]: any } = {};
    if (page !== undefined) params.page = page;
    if (pageSize !== undefined) params.pageSize = pageSize;
    if (roleId !== undefined)
      params.roleId = Array.isArray(roleId) ? roleId.join(",") : roleId;
    if (answered !== undefined) params.answered = answered;

    const response = await api.get("/user/groups", {
      params,
      withCredentials: true,
    });

    if (response.status === 200) {
      return response.data.groups;
    } else {
      console.error("Failed to fetch user group list:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching user group list:", error);
    return [];
  }
};

// Fetch user group by id
export const fetchUserGroup = async (
  groupId: number
): Promise<IChat | null> => {
  try {
    const response = await api.get(`/user/groups/${groupId}`, {
      withCredentials: true,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Failed to fetch user group id:", groupId, response.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching user group:", error);
    return null;
  }
};

// Delete user
export const deleteUser = async (): Promise<boolean> => {
  try {
    const response = await api.delete("/user", { withCredentials: true });
    return response.status === 200;
  } catch (error) {
    console.error("Error during the API call:", error);
    return false;
  }
};
