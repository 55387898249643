import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { IUser } from "../services/userService";

interface UserState {
  user: IUser | null;
  setUser: (user: IUser) => void;
  setAvatar: (avatar: string) => void;
  removeUser: () => void;
}

const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        setUser: (user: IUser) => set({ user }),
        setAvatar: (avatar: string) =>
          set((state) => ({
            user: state.user
              ? {
                  ...state.user,
                  avatar: avatar,
                }
              : null,
          })),
        removeUser: () => set({ user: null }),
      }),
      {
        name: "user-storage",
      }
    )
  )
);

export default useUserStore;
