import { create } from "zustand";
import { ISearch } from "../services/searchService";

interface SearchState {
  lastPath: string | null;
  keyword: string;
  searchResult: ISearch | null;
  setLastPath: (lastPath: string | null) => void;
  setKeyword: (keyword: string) => void;
  setSearchResult: (searchResult: ISearch | null) => void;
}

const useSearchStore = create<SearchState>((set) => ({
  lastPath: null,
  keyword: "",
  searchResult: null,
  setLastPath: (lastPath: string | null) => set({ lastPath }),
  setKeyword: (keyword: string) => set({ keyword }),
  setSearchResult: (searchResult: ISearch | null) => set({ searchResult }),
}));

export default useSearchStore;
