// QAPage.tsx

import { useEffect, useState } from "react";
import QABox, { QAItemType } from "../components/qa-box-grid/qa-box-grid";
import { IGroup, fetchGroups } from "../services/groupService";
import { formatNumber } from "../utils/helpers";
import useUserStore from "../stores/userStore";

const QAPage = () => {
    // States
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    // Stores
    const { user } = useUserStore();

    useEffect(() => {
        const loadGroups = async () => {
            try {
                const fetchedGroups = await fetchGroups(user ? user.userId : null, 1, 100);
                setGroups(fetchedGroups);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching groups:', error);
                setLoading(false);
            }
        };

        loadGroups();
    }, [user]);

    return (
        <div className="page-container">
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-light" role="status" />
                </div>
            ) : (
                <QABox headline="All Questions" items={groups.map(group => ({
                    group_id: group.group_id,
                    title: group.title,
                    price: `${formatNumber(Number(group.price_crypto_primary) ?? 0)} ${group.token_fiat}`,
                    type: QAItemType.TEXT,
                    content: group.question,
                    access: group.role_id !== null,
                }))} />
            )}
        </div>
    );
};

export default QAPage;
