import React, { useCallback, useState } from 'react';
import useUserSettingsStore from '../../stores/userSettingsStore';
import { removeUserSkill } from '../../services/userService';

import './remove-skill-modal.css';

interface ModalProps {
    skillId: number;
    skillName: string;
    onClose: () => void;
}

const RemoveSkillModal: React.FC<ModalProps> = ({ skillId, skillName, onClose }) => {
    // Stores
    const { removeSkill } = useUserSettingsStore();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onClickRemove = useCallback(async () => {
        try {
            setIsLoading(true);

            const removed = await removeUserSkill(skillId);
            if (removed) {
                removeSkill(skillId);
            }

            onClose();
        } catch (error) {
            console.log(`Error while deleting skill ${skillId}:`, error)
        } finally {
            setIsLoading(false);
        }
    }, [skillId, skillName, isLoading]);

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">Remove {skillName} skill?</h3>
                <p className="text-light">Do you really want to remove the {skillName} skill from your profile?</p>
                <div className="text-end mt-4">
                    <button className={`btn btn-danger remove-btn me-2 ${isLoading ? 'disabled' : ''}`} onClick={onClickRemove} disabled={isLoading}>
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        ) : (
                            'Remove Skill'
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-light cancel-btn" onClick={onClose}>Close</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RemoveSkillModal;
