import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { BsPlayFill, BsPauseFill } from 'react-icons/bs';

import './chat-voice-message-item.css';

interface ChatVoiceMessageItemProps {
    audioFile: Blob;
    isOwnMessage: boolean;
}

const ChatVoiceMessageItem: React.FC<ChatVoiceMessageItemProps> = ({ audioFile, isOwnMessage }) => {
    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);

    // States
    const [isPlaying, setIsPlaying] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        if (waveformRef.current) {
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: '#B1B5C1',
                progressColor: 'white',
                cursorColor: 'transparent',
                barWidth: 2,
                barRadius: 3,
                height: 30,
                normalize: true,
                fillParent: true,
            });

            wavesurfer.current.load(URL.createObjectURL(audioFile));

            wavesurfer.current.on('ready', () => {
                if (wavesurfer.current) {
                    const dur = wavesurfer.current.getDuration();
                    setDuration(dur);
                    setElapsedTime(0);
                }
            });

            wavesurfer.current.on('audioprocess', () => {
                if (wavesurfer.current) {
                    setElapsedTime(wavesurfer.current.getCurrentTime());
                }
            });

            wavesurfer.current.on('finish', () => {
                setIsPlaying(false); // Reset isPlaying when audio finishes
            });

            return () => {
                if (wavesurfer.current) {
                    wavesurfer.current.destroy();
                }
            };
        }
    }, [audioFile]);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
        if (wavesurfer.current) {
            wavesurfer.current.playPause();
        }
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="chat-voice-message-item-container">
            <div className="top-section">
                <div className="voice-message" style={{backgroundColor: isOwnMessage ? "#6033FF" : "#131313"}}>
                    <button className="play-pause-btn" onClick={handlePlayPause}>
                        {isPlaying ? <BsPauseFill /> : <BsPlayFill />}
                    </button>
                    <div className="waveform-timer-container">
                        <div id="waveform" ref={waveformRef}></div>
                        <span className="timer" style={{ fontSize: 12 }}>
                            {isPlaying ? formatTime(elapsedTime) : formatTime(duration)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatVoiceMessageItem;
