// DevsPage.tsx

import { useEffect, useState } from "react";
import ItemsGrid from "../components/items-grid/items-grid";
import { IDev, fetchDevs } from "../services/devService";
import { SPACES_ENDPOINT } from "../utils/constants";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';

const DevsPage = () => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    // Access specific query parameters
    const skillId = queryParams.skillId;
    const skillName = queryParams.skillName;

    const [devs, setDevs] = useState<IDev[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const loadSkills = async () => {
            try {
                const fetchedDevs = await fetchDevs(1, 100, skillId ? Number(skillId) : undefined);
                setDevs(fetchedDevs);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching devs:', error);
                setLoading(false);
            }
        };

        loadSkills();
    }, []);

    return (
        <div className="page-container">
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-light" role="status" />
                </div>
            ) : (
                <ItemsGrid to="" title={skillName ? `${decodeURIComponent(skillName as string)} Devs` : "All Devs"} items={devs.map(dev => ({
                    to: `/dev/${dev.user_id}`,
                    imageUrl: dev.avatar ? `${SPACES_ENDPOINT}${dev.avatar}` : undefined,
                    title: dev.username ?? "Unknown",
                }))} />
            )}
        </div>
    );
};

export default DevsPage;
