// ChatMessageRight.tsx
import React, { useEffect, useState } from 'react';
import ChatVoiceMessageItem from './chat-voice-message-item/chat-voice-message-item';
import DOMPurify from 'dompurify';
import UserDefaultAvatar from '../../assets/images/default-user-avatar.jpg';
import { formatDateTime } from '../../utils/helpers';
import { downloadGroupMessageAttachment } from '../../services/groupService';

import './chat-message-list-item.css';

interface ChatMessageRightProps {
    groupId: number;
    messageId: number;
    message?: string | undefined;
    dateTime: string;
    avatar?: string | undefined;
    fileType?: string | undefined;
    filePath?: string | undefined;
    isOwnMessage: boolean;
    isMessageLocked: boolean;
}

const ChatMessageRight: React.FC<ChatMessageRightProps> = ({ groupId, messageId, message, dateTime, avatar, fileType, filePath, isOwnMessage, isMessageLocked }) => {
    const [audioFile, setAudioFile] = useState<Blob | null>(null);
    const [imageFile, settImageFile] = useState<Blob | null>(null);

    useEffect(() => {
        if (filePath && fileType) {
            const donwloadAttachment = async () => {
                const attachmentFile = await downloadGroupMessageAttachment(groupId, messageId);

                if (fileType.includes("audio/webm")) {
                    setAudioFile(attachmentFile);
                } else {
                    settImageFile(attachmentFile);
                }
            }
            donwloadAttachment();
        }
    }, [filePath])

    return (
        <>
            {isOwnMessage ? (
                <>
                    <div className="row justify-content-end align-items-start chat-message-list-item me-2">
                        <div className="col d-flex flex-column align-items-end">
                            <div className="chat-message-list-item-user-avatar mb-2">
                                <img src={avatar ?? UserDefaultAvatar} alt="User Avatar" />
                            </div>
                            {message && message.trim().length > 0 && (
                                <div className="chat-message-list-item-box" style={{ filter: isMessageLocked ? "blur(4px)" : "none" }}>
                                    <div className="chat-message-list-item-message" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
                                    {imageFile && (
                                        <img src={URL.createObjectURL(imageFile)} alt="Chat Image" className="chat-message-image" />
                                    )}
                                </div>
                            )}
                            {audioFile && (
                                <ChatVoiceMessageItem audioFile={audioFile} isOwnMessage={isOwnMessage} />
                            )}
                            <div className="chat-message-list-item-date-time">{formatDateTime(dateTime)}</div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="row justify-content-end align-items-start chat-message-list-item ms-2">
                        <div className="col d-flex flex-column align-items-start">
                            <div className="chat-message-list-item-user-avatar mb-2">
                                <img src={avatar ?? UserDefaultAvatar} alt="User Avatar" />
                            </div>
                            {message && message.trim().length > 0 && (
                                <div className="chat-message-list-item-box text-start" style={{ backgroundColor: "#131313", filter: isMessageLocked ? "blur(4px)" : "none" }}>
                                    <div className="chat-message-list-item-message" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
                                    {imageFile && (
                                        <img src={URL.createObjectURL(imageFile)} alt="Chat Image" className="chat-message-image" />
                                    )}
                                </div>
                            )}
                            {audioFile && (
                                <ChatVoiceMessageItem audioFile={audioFile} isOwnMessage={isOwnMessage} />
                            )}
                        </div>
                        <div className="chat-message-list-item-date-time text-start">{formatDateTime(dateTime)}</div>
                    </div>
                </>
            )}
        </>
    );
};

export default ChatMessageRight;
