import api from "../utils/axiosInstance";

export interface IRole {
  role_id: number;
  name: string;
  creation_date: string;
}

// Fetch roles using axios
export const fetchRoles = async (): Promise<IRole[]> => {
  try {
    const response = await api.get("/roles");
    return response.data.roles;
  } catch (error) {
    console.error("Error fetching roles:", error);
    return [];
  }
};
