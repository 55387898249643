import { create } from "zustand";
import { ISkill } from "../services/skillService";
import { IGroup } from "../services/groupService";
import { IDev } from "../services/devService";

interface DiscoverState {
  isInitialLoading: boolean;
  isLoading: boolean;
  skills: ISkill[];
  popularDevs: IDev[];
  recentDevs: IDev[];
  popularProjects: IDev[];
  popularGroups: IGroup[];
  recentGroups: IGroup[];
  setIsInitialLoading: (isInitialLoading: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setSkills: (skills: ISkill[]) => void;
  setPopularDevs: (devs: IDev[]) => void;
  setRecentDevs: (devs: IDev[]) => void;
  setPopularProjects: (devs: IDev[]) => void;
  setPopularGroups: (groups: IGroup[]) => void;
  setRecentGroups: (groups: IGroup[]) => void;
}

const useDiscoverStore = create<DiscoverState>((set) => ({
  isInitialLoading: false,
  isLoading: false,
  skills: [],
  popularDevs: [],
  recentDevs: [],
  popularProjects: [],
  popularGroups: [],
  recentGroups: [],
  setIsInitialLoading: (isInitialLoading: boolean) =>
    set({ isInitialLoading: isInitialLoading }),
  setIsLoading: (isLoading: boolean) => set({ isLoading: isLoading }),
  setSkills: (skills: ISkill[]) => set({ skills: skills }),
  setPopularDevs: (devs: IDev[]) => set({ popularDevs: devs }),
  setRecentDevs: (devs: IDev[]) => set({ recentDevs: devs }),
  setPopularProjects: (devs: IDev[]) => set({ popularProjects: devs }),
  setPopularGroups: (groups: IGroup[]) => set({ popularGroups: groups }),
  setRecentGroups: (groups: IGroup[]) => set({ recentGroups: groups }),
}));

export default useDiscoverStore;
