import React, { useEffect, useState, useRef } from 'react';
import { formatAmount } from '../../../utils/helpers';

import './voice-message-input.css';

interface VoiceMessageInputProps {
    amount?: number;
    isLoading: boolean;
    onCancelClicked: () => void;
    onSendClicked: (audioFile: File) => void;
}

const VoiceMessageInput: React.FC<VoiceMessageInputProps> = ({
    amount,
    isLoading,
    onCancelClicked,
    onSendClicked,
}) => {
    const [recordingTime, setRecordingTime] = useState(0);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);

                recorder.ondataavailable = event => {
                    audioChunksRef.current.push(event.data);
                };

                recorder.onstart = () => {
                    startTimer();
                };

                recorder.start();
            });

        return () => {
            stopRecording();
            clearInterval(timerRef.current as NodeJS.Timeout);
        };
    }, []);

    const startTimer = () => {
        setRecordingTime(0);
        timerRef.current = setInterval(() => {
            setRecordingTime(prevTime => prevTime + 1);
        }, 1000);
    };

    const stopRecording = () => {
        mediaRecorder?.stop();
        mediaRecorder?.stream.getTracks().forEach(track => track.stop());
        clearInterval(timerRef.current as NodeJS.Timeout);
    };

    const handleCancel = () => {
        stopRecording();
        onCancelClicked();
    };

    const handleSend = async () => {
        if (!mediaRecorder) {
            throw new Error("MediaRecorder not initialized");
        }

        return new Promise<void>((resolve, reject) => {
            mediaRecorder.onstop = () => {
                const mimeType = mediaRecorder.mimeType || 'audio/webm';
                const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
                const fileExtension = mimeType.includes('audio/webm') ? '.webm' : '';
                const audioFile = new File([audioBlob], `voice-message${fileExtension}`, { type: mimeType });
                onSendClicked(audioFile);

                resolve();
            };

            mediaRecorder.onerror = (event) => {
                console.error('MediaRecorder error:', event);
                // @ts-ignore
                reject(event.error || new Error("Unknown MediaRecorder error"));
            };

            stopRecording();
        });
    };

    return (
        <div className="voice-message-input-container">
            <div className="recording-label d-flex align-items-center">
                <div className="recording-dot"></div>
                <span className="recording-time">{new Date(recordingTime * 1000).toISOString().substr(11, 8)}</span>
            </div>
            <div className="voice-message-input-button-container">
                {!isLoading && (
                    <button className="btn btn-outline-dark cancel-btn" onClick={handleCancel}>
                        Cancel
                    </button>
                )}
                {isLoading ? (
                    <button className="btn btn-primary send-btn" disabled={isLoading}>
                        <div className="spinner-border spinner-border-sm" role="status" />
                    </button>
                ) : (
                    <button className="btn btn-primary send-btn" disabled={isLoading} onClick={handleSend}>
                        {amount ? `Send for ${formatAmount(amount)} USDC`: "Send"}
                    </button>
                )}
            </div>
        </div>
    );
};

export default VoiceMessageInput;
