import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './select-skill-modal.css';
import useUserSettingsStore from '../../stores/userSettingsStore';
import { ISkill, fetchSkills } from '../../services/skillService';
import { addSkill } from '../../services/userService';

interface ModalProps {
    onClose: (updated: boolean) => void;
}

const SelectSkillModal: React.FC<ModalProps> = ({ onClose }) => {
    // Stores
    const { userSettings, addSkill: addUserSettingsSkill } = useUserSettingsStore();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedSkill, setSelectedSkill] = useState<ISkill | null>(null);
    const [skills, setSkills] = useState<ISkill[]>([]);

    useEffect(() => {
        const fetchAvailableSkills = async () => {
            try {
                setIsLoading(true);

                const userSkills = userSettings?.skills ?? [];
                const availableSkills = await fetchSkills(1, 100);
                const filteredSkills = availableSkills.filter(skill => {
                    return !userSkills.some(userSkill => userSkill.skill_id === skill.skill_id);
                });
                setSkills(filteredSkills);
            } catch (error) {
                console.log('Error while fetching available skills', error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchAvailableSkills();
    }, [])

    const onHandleAddSkill = async () => {
        if (selectedSkill) {
            try {
                setIsLoading(true);

                const addedSkill = await addSkill(selectedSkill.skill_id);
                if (addedSkill) {
                    addUserSettingsSkill(addedSkill);
                    onClose(true);
                }
            } catch (error) {
                console.log('Error while adding a new skill', error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    // Custom styles for React Select
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: '#131313',
            border: 'none',
            borderRadius: '12px',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            boxShadow: 'none',
            color: 'white',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#6033FF' : 'transparent',
            color: 'white',
            cursor: 'pointer',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#B1B5C1',
            opacity: 1,
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: 'white',
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: '#131313',
        }),
    };

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title">Add New Skill</h3>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border text-light" role="status" />
                    </div>
                ) : (
                    <>
                        <Select
                            classNamePrefix="react-select"
                            className="input-modal-select mt-4"
                            options={skills.map(skill => ({ value: skill.name, label: skill.name }))}
                            value={selectedSkill ? { value: selectedSkill.name, label: selectedSkill.name } : null}
                            onChange={(selectedOption) => setSelectedSkill(selectedOption ? skills.find(skill => skill.name === selectedOption.value) || null : null)}
                            placeholder="Select a skill"
                            styles={customStyles}
                        />
                        <div className="text-end mt-4">
                            <button className="btn btn-primary add-btn me-2" onClick={onHandleAddSkill} disabled={selectedSkill === null}>Add Skill</button>
                            <button className="btn btn-light cancel-btn" onClick={() => onClose(false)}>Close</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SelectSkillModal;
