import React, { useCallback, useState } from 'react';
import useUserSettingsStore from '../../stores/userSettingsStore';
import { removeUserSocial } from '../../services/userService';

import './remove-social-modal.css';

interface ModalProps {
    socialId: number;
    socialName: string;
    onClose: () => void;
}

const RemoveSocialModal: React.FC<ModalProps> = ({ socialId, socialName, onClose }) => {
    // Stores
    const { removeSocial } = useUserSettingsStore();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onClickRemove = useCallback(async () => {
        try {
            setIsLoading(true);

            const removed = await removeUserSocial(socialId);
            if (removed) {
                removeSocial(socialId);
            }
            onClose();
        } catch (error) {
            console.log(`Error while deleting social ${socialId}:`, error)
        } finally {
            setIsLoading(false);
        }
    }, [socialId, socialName, isLoading]);

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">Remove {socialName} social?</h3>
                <p className="text-light">Do you really want to remove the {socialName} social from your profile?</p>
                <div className="text-end mt-4">
                    <button className={`btn btn-danger remove-btn me-2 ${isLoading ? 'disabled' : ''}`} onClick={onClickRemove} disabled={isLoading}>
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        ) : (
                            'Remove Social'
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-light cancel-btn" onClick={onClose}>Close</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RemoveSocialModal;
