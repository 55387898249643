import api from "../utils/axiosInstance";

export interface IGroup {
  group_id: number;
  user_id: number;
  title: string;
  question: string;
  token_fiat: string;
  price_crypto_primary?: number;
  role_id?: number;
}

export interface IGroupMessage {
  message_id: number;
  user_id: number;
  message_text: string;
  avatar?: string;
  username?: string;
  sent_at: string;
  attachment?: IAttachment;
}

export interface IAttachment {
  attachment_id: number;
  file_name: string;
  file_type: string;
  file_path: string;
}

// Fetch groups with optional pagination and userId
export const fetchGroups = async (
  userId: number | null,
  page?: number,
  pageSize?: number
): Promise<IGroup[]> => {
  try {
    const params: Record<string, any> = {};
    if (page !== undefined && pageSize !== undefined) {
      params.page = page;
      params.pageSize = pageSize;
    }
    if (userId !== null) {
      params.userId = userId;
    }

    const response = await api.get("/groups", { params });

    return response.data.groups;
  } catch (error) {
    console.error("Error fetching groups:", error);
    return [];
  }
};

// Create a new group
export const createGroup = async (
  devId: number,
  title: string,
  question: string,
  paymentReference?: string
): Promise<boolean> => {
  try {
    const response = await api.post("/groups", {
      paymentReference,
      dev_user_id: devId,
      question,
      title,
    });

    return response.status === 201;
  } catch (error) {
    console.error("Error during the API call:", error);
    return false;
  }
};

// Fetch messages for a group with optional pagination
export const fetchGroupMessages = async (
  groupId: number,
  page?: number,
  pageSize?: number
): Promise<IGroupMessage[]> => {
  try {
    const params: Record<string, any> = {};
    if (page !== undefined && pageSize !== undefined) {
      params.page = page;
      params.pageSize = pageSize;
    }

    const response = await api.get(`/groups/${groupId}/messages`, { params });

    return response.data.messages;
  } catch (error) {
    console.error("Error fetching group messages:", error);
    return [];
  }
};

// Download an attachment for a group message
export const downloadGroupMessageAttachment = async (
  groupId: number,
  messageId: number
): Promise<Blob | null> => {
  try {
    const response = await api.get(
      `/groups/${groupId}/messages/${messageId}/attachment`,
      {
        responseType: "blob", // Set responseType to 'blob' for file downloads
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error while downloading group message attachment:", error);
    return null;
  }
};

// Create a new message in a group
export const createGroupMessage = async (
  groupId: number,
  message: string,
  file?: File,
  paymentReference?: string
): Promise<IGroupMessage | null> => {
  try {
    const formData = new FormData();
    formData.append(
      "message",
      JSON.stringify({
        text: message,
        paymentReference,
      })
    );

    // Append the file to the form data if provided
    if (file) {
      formData.append("file", file);
    }

    const response = await api.post(`/groups/${groupId}/messages`, formData);

    return response.data;
  } catch (error) {
    console.error("Error during the API call:", error);
    return null;
  }
};

// Create a new payment for a group
export const createGroupPayment = async (
  groupId: number,
  paymentType: number,
  paymentReference?: string
): Promise<boolean> => {
  try {
    const response = await api.post(`/groups/${groupId}/payment`, {
      paymentType,
      paymentReference,
    });

    return response.status === 201;
  } catch (error) {
    console.error("Error during the API call:", error);
    return false;
  }
};

// Mark a group as completed
export const markGroupAsCompleted = async (
  groupId: number
): Promise<boolean> => {
  try {
    const response = await api.post(`/groups/${groupId}/completed`);

    return response.status === 200;
  } catch (error) {
    console.error("Error during the API call:", error);
    return false;
  }
};
