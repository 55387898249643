import api from "../utils/axiosInstance";
import { IDev } from "./devService";
import { IGroup } from "./groupService";
import { ISkill } from "./skillService";

export interface IDiscover {
  skills: ISkill[];
  popularDevs: IDev[];
  recentDevs: IDev[];
  popularProjects: IDev[];
  popularGroups: IGroup[];
  recentGroups: IGroup[];
}

export const fetchDiscoverData = async (
  userId: number | null
): Promise<IDiscover | null> => {
  try {
    const params: Record<string, any> = {};
    if (userId !== null) {
      params.userId = userId;
    }

    const response = await api.get("/discover/", {
      params,
      // Include withCredentials if userId is provided (assuming it requires authentication)
      withCredentials: userId !== null,
    });

    const data = response.data;
    return {
      skills: data.skills,
      popularDevs: data.popularDevs,
      recentDevs: data.recentDevs,
      popularProjects: data.popularProjects,
      popularGroups: data.popularGroups,
      recentGroups: data.recentGroups,
    } as IDiscover;
  } catch (error) {
    console.error("Error during the API call:", error);
    return null;
  }
};
