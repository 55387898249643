// SkillsPage.tsx

import { useEffect, useState } from "react";
import { ISkill, fetchSkills } from "../services/skillService";
import SkillItemsGrid from "../components/skill-items-grid/skill-items-grid";

const SkillsPage = () => {
    const [skills, setSkills] = useState<ISkill[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const loadSkills = async () => {
            try {
                const fetchedSkills = await fetchSkills(1, 100);
                setSkills(fetchedSkills);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching skills:', error);
                setLoading(false);
            }
        };

        loadSkills();
    }, []);

    return (
        <div className="page-container">
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-light" role="status" />
                </div>
            ) : (
                <SkillItemsGrid to="" title="All Skills" items={skills.map(skill => ({
                    to: `/devs?skillId=${skill.skill_id}&skillName=${encodeURIComponent(skill.name)}`,
                    imageUrl: skill.avatar,
                    title: skill.name,
                }))} />
            )}
        </div>
    );
};

export default SkillsPage;
