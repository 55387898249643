// ChatListItem.tsx
import React from 'react';
import { SPACES_ENDPOINT } from '../../utils/constants';
import UserDefaultAvatar from '../../assets/images/default-user-avatar.jpg';
import { calculateTimeAgo } from '../../utils/helpers';
import DOMPurify from 'dompurify';

import './chat-list-item.css';

interface ChatListItemProps {
    group_type: number;
    avatar?: string;
    username?: string;
    lastMessage: string | null;
    sentAt: string | null;
    selected: boolean;
    onClickChat: () => void;
}

const ChatListItem: React.FC<ChatListItemProps> = ({ group_type, avatar, username, lastMessage, sentAt, selected, onClickChat }) => {
    const truncateText = (text: string, maxLength: number): string => {
        const div = document.createElement('div');
        div.innerHTML = text;
        const plainText = div.textContent || div.innerText || '';

        return plainText.length > maxLength ? `${plainText.substr(0, maxLength)}...` : plainText;
    };

    return (
        <div className={`d-flex align-items-center chat-item ${selected ? 'selected' : ''}`} onClick={onClickChat}>
            <div className="user-avatar">
                <img src={avatar ? `${SPACES_ENDPOINT}${avatar}` : UserDefaultAvatar} alt={username} />
            </div>
            <div className="chat-list-item-message ms-3 me-2">
                <div className="user-name">{username ?? "Unknown"}</div>
                {lastMessage ? (
                    <div className="last-message truncate" style={{ color: selected ? "white" : "#B1B5C1" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncateText(lastMessage, 50)) }} />
                ) : (
                    group_type === 2 && (
                        <p className="last-message truncate" style={{ color: selected ? "white" : "#B1B5C1" }}>Beginning of your group chat</p>
                    )
                )}
            </div>
            {sentAt && (
                <div className="ms-auto align-self-end">
                    <div className="time-ago" style={{ color: selected ? "white" : "#B1B5C1" }}>
                        {calculateTimeAgo(sentAt)}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatListItem;
