// ChatList.tsx
import React, { useEffect, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import FolderIcon from '../../assets/icons/folder-icon.png';
import ChatListItem from '../chat-list-item/chat-list-item';
import { CHAT_CATEGORIES } from '../../utils/mockData';
import { Dropdown } from 'react-bootstrap';
import { IChat, fetchUserGroup, fetchUserGroupList } from '../../services/userService';

import './chat-list.css';

interface ChatListProps {
    preSelectedChatId?: number | undefined;
    selectedChatId?: number | undefined;
    onChatSelected: (chat: IChat) => void;
}

const ChatList: React.FC<ChatListProps> = ({ preSelectedChatId, selectedChatId, onChatSelected }) => {
    // States
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const [chats, setChats] = useState<IChat[]>([]);

    useEffect(() => {
        const fetchGroupList = async () => {
            try {
                setIsLoading(true);
                const roles: number[] = [];
                if (selectedCategoryId === 0) { // All Chats
                    roles.push(...[1, 2, 3, 4, 5]);
                } else if (selectedCategoryId === 1) { // Group Chats
                    roles.push(...[4, 5]);
                } else if (selectedCategoryId === 2) { // Questions asked
                    roles.push(...[2]);
                } else if (selectedCategoryId === 3) { // Questions viewed
                    roles.push(...[3]);
                } else { // Questions answered
                    roles.push(...[1]);
                }

                const groups = await fetchUserGroupList(undefined, undefined, roles);

                if (preSelectedChatId) {
                    const preSelectedGroup = groups.find(group => group.group_id === preSelectedChatId);
                    if (preSelectedGroup) {
                        onChatSelected(preSelectedGroup);
                    } else {
                        const fetchedGroup = await fetchUserGroup(preSelectedChatId);
                        if (fetchedGroup) {
                            setChats([fetchedGroup, ...groups]);
                            onChatSelected(fetchedGroup);
                        }
                    }
                } else {
                    setChats(groups);
                }
            } catch (error) {
                console.log('Error while loading user chat list:', error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchGroupList();
    }, [preSelectedChatId, selectedCategoryId]);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    return (
        <div className="chat-list">
            <div
                className="header d-flex align-items-center justify-content-between"
                style={{ cursor: "pointer" }}
                onClick={toggleDropdown}
            >
                <div className="d-flex align-items-center">
                    <div className="rounded-box">
                        <img src={FolderIcon} alt="Folder Icon" />
                    </div>
                    <h4 className="chat-list-headline ms-3">
                        {`${CHAT_CATEGORIES[selectedCategoryId]} (${chats.length})`}
                    </h4>
                </div>
                <Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
                    <Dropdown.Toggle variant="" className="clickable-icon me-2 chat-category-dropdown-menu-toggle" id="dropdown-basic">
                        <BsChevronDown />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="chat-category-dropdown-menu">
                        {CHAT_CATEGORIES.map((category, index) => (
                            <Dropdown.Item key={index} onClick={() => setSelectedCategoryId(index)} active={selectedCategoryId === index}>
                                {category}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="chat-list-divider mt-2 mb-2" />
            <div className="m-2 chat-list-content">
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border text-light" role="status" />
                    </div>
                ) : (
                    chats.length > 0 ? (
                        chats.map(chat => (
                            <ChatListItem
                                key={chat.group_id}
                                group_type={chat.group_type}
                                avatar={chat.lastMessageUser.avatar ?? chat.answerer.avatar}
                                username={chat.lastMessageUser.username ?? chat.answerer.username}
                                lastMessage={chat.message_text}
                                sentAt={chat.sent_at}
                                selected={selectedChatId === chat.group_id}
                                onClickChat={() => onChatSelected(chat)}
                            />
                        ))
                    )
                        : (
                            <p className="text-light text-center">No chats available</p>
                        )
                )}
            </div>
        </div>
    );
};

export default ChatList;
